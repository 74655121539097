import { nanoid } from "nanoid";
import _ from "lodash";
import { SceneColor } from "./types";
import configs from "@src/configs";
import { selectStudioSources } from "@src/selectors/studio";
import { deleteStudioSource, setSelectedSources } from "@src/controller";
import { StudioStage } from "@src/services/StudioService";
import { getRandomEnumValue } from "@src/utils/getRandomColor";
export class StudioScenes {
    constructor() {
        Object.defineProperty(this, "isPanelOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isCreateSceneModalOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeSceneId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "list", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isGridView", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "addModalData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "deleteModalData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.isPanelOpen = true;
        this.isCreateSceneModalOpen = false;
        this.isGridView = false;
        this.activeSceneId = null;
        this.list = {};
    }
    setPanelOpen(isOpen) {
        this.isPanelOpen.set(isOpen);
    }
    setCreateSceneOpen(isOpen) {
        this.isCreateSceneModalOpen.set(isOpen);
    }
    activate(sceneId) {
        // clears selection on scene activation
        setSelectedSources(undefined);
        // Set new scene.id active
        this.activeSceneId.set(sceneId);
        // Update each scene in list active prop
        Object.keys(this.list.peek()).forEach((id) => {
            this.list[id].assign({ active: id === sceneId });
        });
        // Set StudioStage scene container visible
        StudioStage.switchScene(sceneId);
    }
    create(data) {
        let scene;
        const isNewScene = typeof data === "string";
        if (isNewScene) {
            const { width, height, fps } = configs.studio.defaultOutput;
            scene = {
                id: nanoid(),
                name: data,
                width,
                height,
                ratio: width / height,
                active: false,
                color: getRandomEnumValue(SceneColor),
                output: {
                    width,
                    height,
                    fps,
                },
            };
        }
        else
            scene = data;
        this.list[scene.id].set(scene);
        // Create studio stage container for scene sources
        StudioStage.addScene(scene);
        if (isNewScene)
            this.activate(scene.id);
        this.startScenesPreviewUpdate();
    }
    remove(sceneId) {
        const sourceList = selectStudioSources();
        const sources = sourceList[sceneId]?.peek();
        // Delete scene all sources
        if (sources) {
            Object.keys(sources).map((sourceId) => deleteStudioSource(sourceId, sceneId));
        }
        // Delete scene object itself
        this.list[sceneId].delete();
        this.activeSceneId.set(null);
        // Remove the ticker key inside stage
        if (!Object.keys(this.list.peek()).length) {
            StudioStage.removeTicker("autoPreviewUpdate");
        }
        StudioStage.removeContainer(sceneId);
        setSelectedSources(undefined);
    }
    rename(sceneId, name) {
        this.list[sceneId].assign({ name });
    }
    duplicate(sceneId) {
        const id = nanoid();
        const scene = this.list[sceneId].peek();
        const newScene = {
            ...scene,
            id,
            name: `${scene.name} (copy)`,
        };
        this.list[id].set(newScene);
        this.activate(id);
        StudioStage.addScene(newScene);
    }
    assignColor(sceneId, color) {
        this.list[sceneId].assign({ color });
    }
    setGridView(toggle) {
        this.isGridView.set(toggle);
    }
    async updateScenePreview() {
        const activeId = this.activeSceneId?.get();
        if (activeId && this.list[activeId]) {
            const preview = await StudioStage.takeScreenshot();
            activeId && this.list[activeId].assign({ preview });
        }
    }
    startScenesPreviewUpdate() {
        const throttled = _.throttle(this.updateScenePreview.bind(this), 1000);
        StudioStage.addTicker("autoPreviewUpdate", throttled);
    }
    setDeleteModalData(data) {
        this.deleteModalData.set(data);
    }
}

import { nanoid } from "nanoid";
import configs from "@src/configs";
import { ShapeName, SourceKind, SourceType, } from "@src/domain/Studio/types";
import { ENV } from "@src/env";
const defaultVisualOptions = {
    sourceType: SourceType.VISUAL,
    isRetrieval: false,
    isRemote: false,
    lock: false,
    hidden: false,
    positionX: configs.studio.source.defaultPositionX,
    positionY: configs.studio.source.defaultPositionY,
    flipX: false,
    flipY: false,
    rotation: 0,
    transparency: 100,
};
const defaultSoundOptions = {
    sourceType: SourceType.SOUND,
    isRetrieval: false,
    isRemote: false,
    mute: false,
};
const generateTextSource = () => {
    return {
        ...defaultVisualOptions,
        id: nanoid(),
        sourceKind: SourceKind.TEXT,
        source: "Text",
        sourceName: "Text",
        sourceLabel: "Text",
        width: 200,
        height: 100,
        ratio: 1,
        style: {
            line: "single",
            fontFamily: configs.studio.source.defaultFont,
            fontSize: 120,
            fontWeight: "normal",
            fontStyle: "normal",
            lineHeight: 120,
            padding: 0,
            align: "left",
            fill: "#FFFFFF",
            stroke: null,
            dropShadow: null,
            breakWords: true,
            background: null,
        },
    };
};
const generateShapeSource = () => {
    return {
        ...defaultVisualOptions,
        id: nanoid(),
        sourceKind: SourceKind.SHAPE,
        sourceName: "Shape",
        sourceLabel: "Shape",
        width: 400,
        height: 250,
        ratio: 1,
        source: {
            name: ShapeName.RECTANGLE,
            radius: 400,
            color: "#FFFFFF",
            strokeWidth: 0,
            strokeColor: "#000000",
        },
    };
};
const getReadableName = (device, counter) => {
    if (device.label)
        return device.label;
    const uppercase = (s) => s.charAt(0).toUpperCase() + s.slice(1);
    const words = device.kind.replace(/(audio|video)(\w+)/, "$1 $2").split(" ");
    return `${uppercase(words[0])} ${uppercase(words[1])} ${counter}`;
};
const getSourceName = (track) => {
    const [lblName, lblID] = track?.label ? track.label.split(":") : ["Screen", "Capture"];
    const name = lblName.startsWith("web") ? "Tab" : `${lblName[0].toLocaleUpperCase()}${lblName.substr(1)}`;
    const tag = lblName.startsWith("web") ? lblID?.replace(/\//g, "")?.substr(-5) : lblID;
    return `${name}${tag ? ` ${tag}` : ""}`;
};
export const getStreamDimensions = async (stream) => {
    return await new Promise(resolve => {
        let video = document.createElement("video");
        video.muted = true;
        video.srcObject = stream;
        video.onloadedmetadata = () => {
            const ratio = video.videoWidth / video.videoHeight;
            resolve({
                width: configs.studio.source.defaultWidth,
                height: configs.studio.source.defaultWidth / ratio,
                sourceWidth: video.videoWidth,
                sourceHeight: video.videoHeight,
                ratio,
            });
            video = null;
        };
    });
};
const handleFileLoad = async (accept, callback) => {
    return new Promise(resolve => {
        const input = document.createElement("input");
        input.accept = accept;
        input.multiple = false;
        input.hidden = true;
        input.type = "file";
        input.addEventListener("change", async (e) => {
            resolve(await callback(e));
        });
        document.body.appendChild(input);
        input.click();
    });
};
const handleImageFileLoad = async (e) => {
    return await new Promise(resolve => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = event => {
            const image = new Image();
            image.src = event.target.result;
            image.onload = () => {
                const ratio = image.width / image.height;
                resolve({
                    ...defaultVisualOptions,
                    id: nanoid(),
                    sourceWidth: image.width,
                    sourceHeight: image.height,
                    width: configs.studio.source.defaultWidth,
                    height: configs.studio.source.defaultWidth / ratio,
                    ratio,
                    sourceName: file.name,
                    sourceLabel: file.name,
                    sourceKind: SourceKind.IMAGE_FILE,
                    source: image,
                });
            };
        };
        reader.readAsDataURL(file);
    });
};
const handleVideoFileLoad = async (e) => {
    return await new Promise(resolve => {
        const file = e.target.files[0];
        const source = document.createElement("video");
        const reader = new FileReader();
        reader.onload = event => {
            source.src = event.target.result;
            source.onloadedmetadata = () => {
                const ratio = source.videoWidth / source.videoHeight;
                resolve({
                    ...defaultVisualOptions,
                    id: nanoid(),
                    sourceWidth: source.videoWidth,
                    sourceHeight: source.videoHeight,
                    width: configs.studio.source.defaultWidth,
                    height: configs.studio.source.defaultWidth / ratio,
                    ratio,
                    sourceName: file.name,
                    sourceLabel: file.name,
                    sourceKind: SourceKind.VIDEO_FILE,
                    source,
                    loop: false,
                    hideOnEnd: false,
                    playbackOnActive: false,
                });
            };
            source.load();
        };
        reader.readAsDataURL(file);
    });
};
const handleAudioFileLoad = async (e) => {
    return await new Promise(resolve => {
        const file = e.target.files[0];
        const source = document.createElement("audio");
        const reader = new FileReader();
        source.id = nanoid();
        reader.onload = event => {
            source.src = event.target.result;
            source.onloadedmetadata = () => {
                resolve({
                    ...defaultSoundOptions,
                    id: source.id,
                    sourceName: file.name,
                    sourceLabel: file.name,
                    sourceKind: SourceKind.AUDIO_FILE,
                    source,
                    loop: false,
                    playbackOnActive: false,
                });
            };
            source.load();
        };
        reader.readAsDataURL(file);
    });
};
export const handleDisplayLoad = async (displaySurface, sourceKind) => {
    const mediaStream = await LocalStream.getDisplay(configs.studio.getScreenCaptureConstraint(displaySurface));
    const { width, height, ratio, sourceWidth, sourceHeight } = await getStreamDimensions(mediaStream);
    const [audioTrack] = mediaStream.getAudioTracks();
    const [videoTrack] = mediaStream.getVideoTracks();
    const name = getSourceName(videoTrack);
    return {
        ...defaultVisualOptions,
        id: mediaStream.id,
        sourceLabel: name,
        sourceName: name,
        sourceKind,
        sourceWidth,
        sourceHeight,
        width,
        height,
        ratio,
        hasAudio: !!audioTrack,
        audioSettings: audioTrack?.getSettings(),
        videoSettings: videoTrack.getSettings(),
        source: mediaStream,
    };
};
const handleMediaDeviceLoad = async (sourceKind, deviceId, sourceName) => {
    const mediaType = sourceKind === SourceKind.AUDIO_INPUT ? "audio" : "video";
    const mediaStream = await LocalStream.getMediaTrack(mediaType, { deviceId: { exact: deviceId } });
    const sourceOptions = {
        id: mediaStream.id,
        sourceLabel: sourceName,
        sourceName,
        sourceKind,
        source: mediaStream,
        settings: mediaStream.getTracks()[0].getSettings(),
    };
    if (sourceKind === SourceKind.VIDEO_INPUT) {
        const { width, height, sourceWidth, sourceHeight, ratio } = await getStreamDimensions(mediaStream);
        return {
            ...defaultVisualOptions,
            ...sourceOptions,
            sourceWidth,
            sourceHeight,
            width,
            height,
            ratio,
            flipX: true,
        };
    }
    return {
        ...defaultSoundOptions,
        ...sourceOptions,
    };
};
export const LocalStream = {
    async devices(type) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        switch (type) {
            case SourceKind.AUDIO_INPUT:
            case SourceKind.AUDIO_OUTPUT:
            case SourceKind.VIDEO_INPUT:
            case SourceKind.VIDEO_OUTPUT:
                return devices.filter((device) => device.kind === type);
            case "input":
            case "output":
                return devices.filter((device) => device.kind.endsWith(type));
            default:
                return devices;
        }
    },
    async getMediaTrack(kind, constraints) {
        return await navigator.mediaDevices.getUserMedia({
            [kind]: {
                ...configs.mediaStream[kind],
                ...constraints,
            },
        });
    },
    async getDisplay(constrains) {
        const cons = {
            ...configs.mediaStream.display,
            ...constrains,
        };
        return await navigator.mediaDevices.getDisplayMedia(cons);
    },
    async loadFile(type) {
        switch (type) {
            case SourceKind.IMAGE_FILE:
                return handleFileLoad("image/*", handleImageFileLoad);
            case SourceKind.VIDEO_FILE:
                return handleFileLoad("video/*", handleVideoFileLoad);
            case SourceKind.AUDIO_FILE:
                return handleFileLoad("audio/*", handleAudioFileLoad);
            default:
                return null;
        }
    },
    async getMediaList() {
        let counter = 0;
        const count = () => (counter += 1);
        const list = [
            {
                id: count(),
                label: "Text",
                caption: "Add a text to your canvas.",
                sourceKind: SourceKind.TEXT,
                getSource: generateTextSource,
            },
            {
                id: count(),
                label: "Shape",
                caption: "Add a divider, rectangle or ellipse.",
                sourceKind: SourceKind.SHAPE,
                getSource: generateShapeSource,
            },
            {
                id: count(),
                label: "Image",
                caption: "Add your images to your canvas. We support all common image files.",
                sourceKind: SourceKind.IMAGE_FILE,
                getStream: () => LocalStream.loadFile(SourceKind.IMAGE_FILE),
            },
            {
                id: count(),
                label: "Video",
                caption: "Add your videos to your canvas. We support all common video files.",
                sourceKind: SourceKind.VIDEO_FILE,
                getStream: () => LocalStream.loadFile(SourceKind.VIDEO_FILE),
            },
            {
                id: count(),
                label: "Audio",
                caption: "Add audio files to your stream. We support all common audio files.",
                sourceKind: SourceKind.AUDIO_FILE,
                getStream: () => LocalStream.loadFile(SourceKind.AUDIO_FILE),
            },
            {
                id: count(),
                label: "Display Capture",
                caption: "Capture your entire screen or monitor.",
                sourceKind: SourceKind.DISPLAY_CAPTURE,
                getStream: () => handleDisplayLoad("monitor", SourceKind.DISPLAY_CAPTURE),
            },
            {
                id: count(),
                label: "Window Capture",
                caption: "Capture a specific window.",
                sourceKind: SourceKind.WINDOW_CAPTURE,
                getStream: () => handleDisplayLoad("window", SourceKind.WINDOW_CAPTURE),
            },
            {
                id: count(),
                label: "Browser Capture",
                caption: "Capture a specific browser window.",
                sourceKind: SourceKind.BROWSER_CAPTURE,
                getStream: () => handleDisplayLoad("browser", SourceKind.BROWSER_CAPTURE),
            },
        ];
        const addCaptureDevices = (devices, sourceKind) => {
            for (const device of devices) {
                if (device.deviceId !== "default") {
                    const id = count();
                    const sourceName = getReadableName(device, id);
                    list.push({
                        id,
                        label: sourceName,
                        caption: sourceKind === SourceKind.AUDIO_INPUT
                            ? "Add your Build-in Mic, USB Mic or any other USB Audio Device."
                            : "Add your Build-in Webcam, USB Webcam or any other USB Camera Device.",
                        sourceKind,
                        getStream: async () => await handleMediaDeviceLoad(sourceKind, device.deviceId, sourceName),
                    });
                }
            }
        };
        const audioDevices = await this.devices(SourceKind.AUDIO_INPUT);
        const videoDevices = await this.devices(SourceKind.VIDEO_INPUT);
        addCaptureDevices(audioDevices, SourceKind.AUDIO_INPUT);
        addCaptureDevices(videoDevices, SourceKind.VIDEO_INPUT);
        return list;
    },
};
if (ENV.IS_DEV)
    window.LocalStream = LocalStream;

import { Chat } from "../Chat";
import { StudioLive } from "@src/services/StudioService";
export class StudioStream {
    constructor() {
        Object.defineProperty(this, "liveStartTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isLive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "videoStream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "liveChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.isLive = false;
        this.videoStream = null;
        this.liveChat = new Chat(true, false);
    }
    startStream(data) {
        this.videoStream.set(data);
        this.isLive.set(true);
        this.liveStartTime.set(new Date());
        StudioLive.instance.start(data.video.uuid);
    }
    stopStream() {
        this.isLive.set(false);
        StudioLive.instance.stop();
    }
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var SelfStreamService_1;
import { injectable } from "inversify";
import loglevel from "loglevel";
import { AnalyzerFilter, AudioFilterChain, VolumeFilter } from "./AudioFilter";
import { MediaTrackType } from "./types";
import { VideoFilter } from "./VideoFilter";
import configs from "@src/configs";
import { MediaInputErrors } from "@src/domain/SelfStream/types";
import { LocalStream } from "@src/services/localStream";
import { selectRoomSelfPeer, selectMeetingUser, selectSelfScreenStream, selectSelfStream, selectStreamSettings, selectRoomPeer, } from "@src/selectors";
import { SelfScreenStream } from "@src/domain/SelfScreenStream/SelfScreenStream";
import { toggleScreenOn } from "@src/controller/userMedia";
// TODO: changing domain entities?
let SelfStreamService = SelfStreamService_1 = class SelfStreamService {
    constructor() {
        Object.defineProperty(this, "audioTrackEndListener", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "videoTrackEndListener", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "screenTrackEndListener", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "originalAudioStream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "originalVideoStream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "audioContext", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new AudioContext()
        });
        Object.defineProperty(this, "audioFilters", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "videoFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new VideoFilter()
        });
        Object.defineProperty(this, "clonedStream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "getTrackConstraints", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (type) => {
                const streamSettingsObservable = selectStreamSettings();
                if (type === MediaTrackType.audio) {
                    const { echoCancellation, 
                    // autoGainControl,
                    noiseSuppression, audioInput, } = streamSettingsObservable.peek();
                    return {
                        deviceId: audioInput?.deviceId,
                        echoCancellation,
                        // autoGainControl,
                        noiseSuppression,
                    };
                }
                if (type === MediaTrackType.video) {
                    const { videoInput, videoInputResolution } = streamSettingsObservable.peek();
                    return {
                        deviceId: videoInput?.deviceId,
                        ...videoInputResolution,
                    };
                }
                if (type === MediaTrackType.screen) {
                    const { screenInputResolution } = streamSettingsObservable.peek();
                    return {
                        ...screenInputResolution,
                    };
                }
            }
        });
    }
    async initUserMedia() {
        const streamSettingsObservable = selectStreamSettings();
        const selfStreamObservable = selectSelfStream();
        streamSettingsObservable.videoInputError.set(null);
        streamSettingsObservable.audioInputError.set(null);
        const isMicOn = streamSettingsObservable.isMicOn.peek();
        if (isMicOn)
            await this.handleCaptureMedia(MediaTrackType.audio);
        selfStreamObservable.enableDisableAudioTrack(isMicOn);
        const isCamOn = streamSettingsObservable.isCamOn.peek();
        if (isCamOn)
            await this.handleCaptureMedia(MediaTrackType.video);
        const isBlur = streamSettingsObservable.isBlurBackground.peek();
        if (isBlur && isCamOn)
            await this.toggleBlurBackgroundStatus(isBlur);
    }
    handleAudioFilter(stream) {
        const streamSettingsObservable = selectStreamSettings();
        const meetingUserObservable = selectMeetingUser();
        const audioContext = this.audioContext;
        const sourceNode = audioContext.createMediaStreamSource(stream);
        const volume = streamSettingsObservable.volume.peek();
        const volumeFilter = new VolumeFilter(audioContext, volume);
        const analyzerFilter = new AnalyzerFilter(audioContext);
        const filterChain = new AudioFilterChain([volumeFilter, analyzerFilter]);
        const outputNode = filterChain.processChain(sourceNode);
        const mediaStreamDestination = audioContext.createMediaStreamDestination();
        outputNode.connect(mediaStreamDestination);
        audioContext.resume();
        this.audioFilters = filterChain;
        meetingUserObservable.analyzerFilter.set(analyzerFilter);
        return mediaStreamDestination.stream;
    }
    getAnalyzerFilter() {
        if (this.audioFilters)
            return this.audioFilters.getFilterByType(AnalyzerFilter);
    }
    async toggleMicStatus(enabled) {
        const streamSettingsObservable = selectStreamSettings();
        const selfStreamObservable = selectSelfStream();
        const audioTrack = selfStreamObservable.audioTrack.peek();
        let didCapture;
        // we always keep a track for audio. even if mic is off, so we always need to capture an audio track
        // if there are no tracks available
        if (!audioTrack || audioTrack.readyState !== "live") {
            didCapture = await this.handleCaptureMedia(MediaTrackType.audio);
            selfStreamObservable.enableDisableAudioTrack(didCapture && enabled);
            streamSettingsObservable.isMicOn.set(didCapture && enabled);
        }
        else {
            selfStreamObservable.enableDisableAudioTrack(enabled);
            streamSettingsObservable.isMicOn.set(enabled);
        }
    }
    async toggleCamStatus(enabled) {
        const streamSettingsObservable = selectStreamSettings();
        const isBlur = streamSettingsObservable.isBlurBackground.peek();
        if (enabled) {
            const didCapture = await this.handleCaptureMedia(MediaTrackType.video);
            streamSettingsObservable.isCamOn.set(didCapture);
            if (isBlur)
                this.handleBlur(true);
        }
        else {
            streamSettingsObservable.isCamOn.set(false);
            this.cleanupVideoStream();
        }
    }
    cleanupVideoStream() {
        this.removeTrackEndListener(MediaTrackType.video);
        selectSelfStream().removeVideoTrack();
        if (this.clonedStream)
            this.clonedStream.getVideoTracks()[0]?.stop();
        this.clonedStream = null;
    }
    async toggleScreenStatus(enabled) {
        // todo fix render issue on selectRoomSelfPeer.toggleScreen
        const selfPeerObservable = selectRoomSelfPeer();
        const roomPeerObservable = selectRoomPeer(selfPeerObservable.peerId.peek());
        const selfScreenStreamObservable = selectSelfScreenStream();
        this.removeTrackEndListener(MediaTrackType.screen);
        if (enabled) {
            const didCapture = await this.handleCaptureScreen();
            roomPeerObservable.toggleScreen(didCapture);
        }
        else {
            if (selfScreenStreamObservable.peek())
                selfScreenStreamObservable.removeScreenTracks?.();
            roomPeerObservable.toggleScreen(false);
        }
    }
    async toggleEchoCancellationStatus(enabled) {
        const streamSettingsObservable = selectStreamSettings();
        streamSettingsObservable.echoCancellation.set(enabled);
        return await this.applyTrackConstraints(MediaTrackType.audio);
    }
    async toggleAutoGainControlStatus(enabled) {
        const streamSettingsObservable = selectStreamSettings();
        streamSettingsObservable.autoGainControl.set(enabled);
        return await this.applyTrackConstraints(MediaTrackType.audio);
    }
    async toggleNoiseSuppressionStatus(enabled) {
        const streamSettingsObservable = selectStreamSettings();
        streamSettingsObservable.noiseSuppression.set(enabled);
        return await this.applyTrackConstraints(MediaTrackType.audio);
    }
    async handleVideoResolution(resolution) {
        const streamSettingsObservable = selectStreamSettings();
        streamSettingsObservable.videoInputResolution.set(resolution);
        return await this.applyTrackConstraints(MediaTrackType.video);
    }
    async handleScreenResolution(resolution) {
        const streamSettingsObservable = selectStreamSettings();
        streamSettingsObservable.screenInputResolution.set(resolution);
        // TODO: add logic for applying constraints for screen resolution
    }
    async handleBlur(isBlur) {
        if (!isBlur) {
            await this.handleCaptureMedia(MediaTrackType.video);
            return await this.videoFilter.disableEffect();
        }
        try {
            const $selfStream = selectSelfStream();
            const $streamSettings = selectStreamSettings();
            const { videoInputResolution: resolution, isCamOn } = $streamSettings.peek();
            const newStream = $selfStream.stream.peek().clone();
            if (this.clonedStream)
                this.clonedStream.getVideoTracks()[0].stop();
            this.clonedStream = newStream;
            const shouldApply = this.videoFilter.updateConfig({ stream: newStream, type: "blur-bg", ...resolution });
            if (!shouldApply)
                return;
            const filteredStream = await this.videoFilter.applyEffect();
            const newVideoTrack = filteredStream.getVideoTracks()[0];
            newVideoTrack.enabled = isCamOn;
            $selfStream.removePreviousTracks("video");
            $selfStream.addVideoTrack(newVideoTrack);
        }
        catch (error) {
            loglevel.error("Could not blur video stream");
        }
    }
    async toggleBlurBackgroundStatus(isBlur) {
        const streamSettingsObservable = selectStreamSettings();
        // TODO: check the safari browser
        await this.handleBlur(isBlur);
        streamSettingsObservable.isBlurBackground.set(isBlur);
    }
    async updateVolume(volume) {
        const streamSettingsObservable = selectStreamSettings();
        if (volume < 0 || volume > 100) {
            throw new Error("the amount of volume is not correct it should be between 0 - 100");
        }
        streamSettingsObservable.volume.set(volume);
        const audioFilters = this.audioFilters;
        const volumeFilter = audioFilters.getFilterByType(VolumeFilter);
        if (volumeFilter)
            volumeFilter.updateVolume(volume);
    }
    async changeInputDevice(device) {
        const streamSettingsObservable = selectStreamSettings();
        const selfStreamObservable = selectSelfStream();
        if (device.kind === "audioinput") {
            streamSettingsObservable.audioInput.set(device);
            this.removeTrackEndListener(MediaTrackType.audio);
            selfStreamObservable.removeAudioTrack();
            return await this.toggleMicStatus(streamSettingsObservable.isMicOn.peek());
        }
        else if (device.kind === "videoinput") {
            streamSettingsObservable.videoInput.set(device);
            this.removeTrackEndListener(MediaTrackType.video);
            selfStreamObservable.removeVideoTrack();
            return await this.toggleCamStatus(streamSettingsObservable.isCamOn.peek());
        }
        else if (device.kind === "audiooutput") {
            streamSettingsObservable.audioOutput.set(device);
        }
    }
    async clear() {
        // clear screenStream related resources
        await this.toggleScreenStatus(false);
        // clear selfStream related resources
        this.removeTrackEndListener(MediaTrackType.audio);
        this.removeTrackEndListener(MediaTrackType.video);
        selectSelfStream().clear();
        await this.audioContext.close();
        this.audioContext = new AudioContext();
        //TODO: should I disconnect source node and destination node created in the meantime?
        this.originalAudioStream?.getTracks().forEach(track => {
            track.stop();
        });
        this.originalAudioStream = null;
        this.originalVideoStream?.getTracks().forEach(track => {
            track.stop();
        });
        this.originalVideoStream = null;
    }
    async handleCaptureMedia(type) {
        const streamSettingsObservable = selectStreamSettings();
        const selfStreamObservable = selectSelfStream();
        try {
            const constraints = this.getTrackConstraints(type);
            // todo fix track type
            const capturedStream = await LocalStream.getMediaTrack(type, constraints);
            if (type === MediaTrackType.audio)
                this.originalAudioStream = capturedStream;
            if (type === MediaTrackType.video)
                this.originalVideoStream = capturedStream;
            const track = type === MediaTrackType.audio ? capturedStream.getAudioTracks()[0] : capturedStream.getVideoTracks()[0];
            const filteredTrack = type === MediaTrackType.audio ? this.handleAudioFilter(capturedStream).getAudioTracks()[0] : track;
            this.removeTrackEndListener(type);
            selfStreamObservable.addTrack(filteredTrack);
            // handle track end event
            this.addTrackEndListener(type, filteredTrack);
            // remove previous errors
            if (type === MediaTrackType.video)
                streamSettingsObservable.videoInputError.set(null);
            if (type === MediaTrackType.audio)
                streamSettingsObservable.audioInputError.set(null);
            // set input video resolution
            if (type === MediaTrackType.video) {
                const settings = track.getSettings();
                streamSettingsObservable.videoInputResolution.set({ width: settings.width, height: settings.height });
            }
            // set input device
            await this.setInputDevice(type, track.label);
            return true;
        }
        catch (error) {
            loglevel.error("failed to get userMedia streams", error);
            const errorType = SelfStreamService_1.findErrorType(error);
            if (type === MediaTrackType.video) {
                streamSettingsObservable.videoInputError.set(errorType);
                streamSettingsObservable.videoInput.set(null);
                selfStreamObservable.removeVideoTrack();
                streamSettingsObservable.isCamOn.set(false);
            }
            if (type === MediaTrackType.audio) {
                streamSettingsObservable.audioInputError.set(errorType);
                streamSettingsObservable.audioInput.set(null);
                streamSettingsObservable.isMicOn.set(false);
                selfStreamObservable.removeAudioTrack();
            }
            return false;
        }
    }
    async handleCaptureScreen() {
        const selfPeerObservable = selectRoomSelfPeer();
        const streamSettingsObservable = selectStreamSettings();
        const selfScreenStreamObservable = selectSelfScreenStream();
        try {
            const capturedStream = await LocalStream.getDisplay(configs.mediaStream.display);
            const videoTrack = capturedStream.getVideoTracks()[0];
            if (!selfPeerObservable.selfScreenStream.stream?.peek()) {
                const selfScreenStream = new SelfScreenStream();
                selfPeerObservable.setSelfScreenStream(selfScreenStream);
            }
            selfScreenStreamObservable.addScreenTracks(capturedStream);
            // handle track end event
            this.addTrackEndListener(MediaTrackType.screen, videoTrack);
            // set input video screen resolution
            const settings = videoTrack.getSettings();
            streamSettingsObservable.screenInputResolution.set({ width: settings.width, height: settings.height });
            return true;
        }
        catch {
            if (selfScreenStreamObservable.peek())
                selfScreenStreamObservable?.removeScreenTracks();
            // todo fix render issue on selfPeerObservable.toggleScreen
            selfPeerObservable.toggleScreen(false);
            return false;
        }
    }
    handleTrackEnd(type) {
        const streamSettingsObservable = selectStreamSettings();
        if (type === MediaTrackType.audio)
            this.toggleMicStatus(streamSettingsObservable.isMicOn.peek());
        if (type === MediaTrackType.video)
            this.toggleCamStatus(streamSettingsObservable.isCamOn.peek());
        if (type === MediaTrackType.screen)
            void toggleScreenOn(false);
    }
    static findErrorType(error) {
        if (!(error instanceof DOMException))
            return MediaInputErrors.UnrecognizedError;
        switch (error.name) {
            case "NotAllowedError":
                return MediaInputErrors.NotAllowedError;
            case "NotFoundError":
                return MediaInputErrors.NotFoundError;
            case "OverconstrainedError":
                return MediaInputErrors.OverconstrainedError;
            default:
                return MediaInputErrors.UnrecognizedError;
        }
    }
    async applyTrackConstraints(type) {
        const selfStreamObservable = selectSelfStream();
        const track = type === MediaTrackType.audio ? selfStreamObservable.audioTrack.peek() : selfStreamObservable.videoTrack.peek();
        const constraints = this.getTrackConstraints(type);
        return await track.applyConstraints(constraints);
    }
    removeTrackEndListener(type) {
        if (type === MediaTrackType.screen) {
            const selfScreenStream = selectSelfScreenStream().peek();
            selfScreenStream?.videoTrack?.removeEventListener("ended", this.screenTrackEndListener);
            this.screenTrackEndListener = null;
        }
        else {
            const selfStream = selectSelfStream().peek();
            if (type === MediaTrackType.audio) {
                selfStream?.audioTrack?.removeEventListener("ended", this.audioTrackEndListener);
                this.audioTrackEndListener = null;
            }
            if (type === MediaTrackType.video) {
                selfStream?.videoTrack?.removeEventListener("ended", this.videoTrackEndListener);
                this.videoTrackEndListener = null;
            }
        }
    }
    addTrackEndListener(type, track) {
        const handleTrackEnd = () => {
            this.handleTrackEnd(type);
        };
        track.addEventListener("ended", handleTrackEnd);
        if (type === MediaTrackType.video)
            this.videoTrackEndListener = handleTrackEnd;
        if (type === MediaTrackType.audio)
            this.audioTrackEndListener = handleTrackEnd;
        if (type === MediaTrackType.screen)
            this.screenTrackEndListener = handleTrackEnd;
    }
    async setInputDevice(type, label) {
        const streamSettingsObservable = selectStreamSettings();
        const deviceKind = type === MediaTrackType.video ? "videoinput" : "audioinput";
        const devices = await LocalStream.devices();
        const selectedDevice = devices.find(device => device.label === label && device.kind === deviceKind);
        if (type === MediaTrackType.video)
            streamSettingsObservable.videoInput.set(selectedDevice);
        if (type === MediaTrackType.audio)
            streamSettingsObservable.audioInput.set(selectedDevice);
    }
};
SelfStreamService = SelfStreamService_1 = __decorate([
    injectable()
], SelfStreamService);
export { SelfStreamService };

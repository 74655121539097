import EventEmitter from "events";
import { Container, decorate, injectable } from "inversify";
import { SelfStreamService } from "./services/SelfStreamService/";
import { ChatService } from "./services/ChatService/ChatService";
import { TYPES } from "./ioc.type";
import { SpeakerService } from "./services/SpeakerService";
import { RaisedHandService } from "./services/RaiseHandService/RaiseHandService";
import { ENV } from "@src/env";
import { Libp2pCommunicator } from "@src/services/PeerCommunicator";
import { RoomService } from "@src/services/RoomService";
import { PeerService } from "@src/services/PeerService";
import { RoomsApiService } from "@src/services/RoomsApiService";
import { Calendar, CalendarUtils } from "@src/services/Calendar";
import { Authorizer } from "@src/services/authorization/Authorizer";
import { TingApiService } from "@src/services/TingApiService";
import { RTCConnectionService } from "@src/services/RTCConnectionService";
import { CandidateService } from "@src/services/CandidateService";
decorate(injectable(), EventEmitter);
export const container = new Container({ defaultScope: "Singleton" });
container.bind(TYPES.PeerCommunicator).to(Libp2pCommunicator);
container.bind(TYPES.RoomService).to(RoomService);
container.bind(TYPES.PeerService).to(PeerService);
container.bind(TYPES.RoomsApiService).to(RoomsApiService);
container.bind(TYPES.Calender).to(Calendar);
container.bind(TYPES.CalenderUtils).to(CalendarUtils).inTransientScope();
container.bind(TYPES.SelfStreamService).to(SelfStreamService);
container.bind(TYPES.Authorizer).to(Authorizer);
container.bind(TYPES.TingApiService).to(TingApiService);
container.bind(TYPES.ChatService).to(ChatService);
container.bind(TYPES.RTCConnectionService).to(RTCConnectionService);
container.bind(TYPES.SpeakerService).to(SpeakerService);
container.bind(TYPES.CandidateService).to(CandidateService);
container.bind(TYPES.RaiseHandService).to(RaisedHandService);
export function bindDependencies(func, dependencies) {
    const injections = dependencies.map(dependency => {
        return container.get(dependency);
    });
    return func(...injections);
}
if (ENV.IS_DEV)
    window.container = container;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Button, ChatTextField, SelectBox, Text, ThreeDotsIcon, Loading } from "@ting/ting-ui-components";
import { CloseOutlinedIcon, EmojiOutlinedIcon, FilesOutlinedIcon, ReplyOutlinedIcon, } from "@ting/ting-ui-components/dist/icons";
import { removeRepliedMessage, sendTextChatMessage, sendFileChat } from "@src/controller";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectRepliedMessage } from "@src/selectors/chat";
import { classNameFactory } from "@src/utils/dom";
import { lazyLoad } from "@src/utils/lazyLoad";
import { LiveChatWebTorrentUtil } from "@src/services/WebtorrentService";
import "./chatFooter.scss";
const cn = classNameFactory("chat-footer");
const LazyEmojiPicker = lazyLoad(() => import("@src/components/Chat/EmojiPicker"), module => module.EmojiPicker, {
    fallback: (_jsx("div", { className: cn("fallback"), children: _jsx(Loading, {}) })),
});
export const ChatFooter = props => {
    const { options = true } = props;
    const repliedMessage = useSubscribe(selectRepliedMessage());
    const [showPicker, setShowPicker] = useState(false);
    const [messageText, setMessageText] = useState("");
    const fileInputRef = useRef();
    const textAreaRef = useRef();
    const togglePicker = () => setShowPicker(!showPicker);
    const handleNewMessageChange = (e) => {
        const text = e.currentTarget.value;
        setMessageText(text);
    };
    function sendMessage() {
        sendTextChatMessage(messageText);
        setMessageText("");
        // requestAnimationFrame(() => {
        //   adjustScroll(true);
        // });
    }
    const handleKeyDown = (event) => {
        if (event.key === "Enter" || event.keyCode === 13) {
            event.preventDefault();
            if (messageText.length >= 1) {
                sendMessage();
            }
        }
    };
    const handleFileChange = (event) => {
        const input = event.target;
        if (!input || !input.files || input.files.length !== 1) {
            return;
        }
        const selectedFile = input.files[0];
        LiveChatWebTorrentUtil.Instance.seedFile(selectedFile).then(fileContent => {
            sendFileChat(fileContent);
        });
    };
    useEffect(() => {
        if (textAreaRef.current && repliedMessage) {
            textAreaRef.current.focus();
        }
    }, [repliedMessage]);
    return (_jsxs("div", { className: cn(""), children: [repliedMessage && (_jsxs("div", { className: cn("reply-message"), children: [_jsx(ReplyOutlinedIcon, { width: 24 }), _jsxs("div", { className: cn("reply-message-content"), children: [_jsxs(Text, { size: "body-medium", children: ["Replying to ", repliedMessage.from.userName] }), _jsxs(Text, { className: cn("reply-message-text"), size: "body-small", type: "sub", children: [typeof repliedMessage.content === "string"
                                        ? repliedMessage.content.slice(0, 97)
                                        : repliedMessage.content.fileName, typeof repliedMessage.content === "string" && (repliedMessage.content.length > 97 ? "..." : "")] })] }), _jsx(Button, { v2: true, icon: _jsx(CloseOutlinedIcon, { height: "20px", width: "20px" }), size: "small", type: "neutral", onClick: removeRepliedMessage })] })), options && (_jsxs("div", { className: cn("message-options"), children: [_jsxs("div", { className: cn("send-to"), children: [_jsx(Text, { size: "body-medium", children: "Send to:" }), _jsx(SelectBox, { options: [
                                    { label: "Everyone", value: "0" },
                                    { label: "Only to Host", value: "1" },
                                ], value: { label: "Everyone", value: "0" }, size: "small", className: cn("message-visibility") })] }), _jsx(Button, { v2: true, icon: _jsx(EmojiOutlinedIcon, { width: 20 }), size: "small", type: "neutral", variant: "primary", onClick: () => setTimeout(() => {
                            setShowPicker(true);
                        }) }), _jsx(Button, { v2: true, icon: _jsx(FilesOutlinedIcon, { width: 20 }), size: "small", type: "neutral", variant: "primary", onClick: () => {
                            fileInputRef?.current?.click();
                        } }), _jsx(Button, { v2: true, icon: _jsx(ThreeDotsIcon, { width: 18, height: 18 }), size: "small", type: "neutral", variant: "primary" }), _jsx("input", { type: "file", className: cn("file-input"), ref: fileInputRef, onChange: handleFileChange })] })), _jsx(ChatTextField, { value: messageText, placeholder: "Send message...", disabled: false, onKeyPress: handleKeyDown, onChange: handleNewMessageChange, onSend: sendMessage, ref: textAreaRef }), showPicker && (_jsx(LazyEmojiPicker, { handlePick: emoji => setMessageText(messageText + emoji), togglePicker: togglePicker }))] }));
};

import { nanoid } from "nanoid";
import logger from "loglevel";
import { AudioMixer, StudioStage } from "@src/services/StudioService";
import { ShapeName, SourceAlignment, SourceKind, SourceReorder, SourceType, } from "@src/domain/Studio/types";
import { selectActiveSceneId } from "@src/selectors/studio";
import { LocalStream } from "@src/services/localStream";
import configs from "@src/configs";
export class StudioSources {
    constructor() {
        Object.defineProperty(this, "isPanelOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isObjectPropertiesPanelOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "activeSourceId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "list", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "addModalData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "deleteModalData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "selected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.isPanelOpen = true;
        this.list = {};
    }
    getList(sceneId) {
        const activeSceneId = selectActiveSceneId().peek();
        const scene = sceneId || activeSceneId;
        return this.list[scene];
    }
    getSource(sourceId, sceneId) {
        return this.getList(sceneId)[sourceId || this.activeSourceId.peek()];
    }
    setPanelOpen(isOpen) {
        this.isPanelOpen.set(isOpen);
    }
    setObjectPropertiesPanelOpen(isOpen) {
        this.isObjectPropertiesPanelOpen.set(isOpen);
    }
    activate(sourceId) {
        const list = this.getList().peek();
        this.activeSourceId.set(sourceId);
        if (this.isObjectPropertiesPanelOpen.peek() === undefined) {
            this.setObjectPropertiesPanelOpen(true);
        }
        Object.keys(list).forEach((id) => {
            this.getSource(id).assign({ active: id === sourceId });
            if (id === sourceId)
                StudioStage.highlightSourceFrame(sourceId);
        });
        const source = this.getSource(sourceId).peek();
        if ([SourceKind.VIDEO_FILE, SourceKind.AUDIO_FILE].includes(source?.sourceKind) &&
            source?.playbackOnActive) {
            void source.source?.play();
        }
    }
    deactiveSources() {
        const scene = this.getList().peek();
        this.activeSourceId.set(null);
        this.isObjectPropertiesPanelOpen.set(undefined);
        if (scene && Object.keys(scene).length) {
            Object.keys(scene).forEach((id) => {
                this.getSource(id).assign({ active: false });
                StudioStage.removeSourcesFrame();
            });
        }
    }
    activateNextPossibleSource(sourceId) {
        const sourcesId = Object.keys(this.getList().peek() || {});
        if (sourcesId.length === 1)
            this.activate(sourcesId[0]);
        else if (sourcesId.length > 0) {
            const lastIndex = sourcesId.indexOf(sourceId);
            this.activate(lastIndex === 0 ? sourcesId[lastIndex + 1] : sourcesId[lastIndex - 1]);
        }
        else
            this.activeSourceId.set(undefined);
    }
    addSoundSource(index, source, sceneId) {
        const soundSource = { ...source, index, volume: source.volume || 10 };
        // Add source to state
        this.getSource(source.id, sceneId).set(soundSource);
        // Add source to mixer
        AudioMixer.instance.addAudioSource(soundSource);
    }
    addVisualSource(index, source, sceneId) {
        const visualSource = { ...source, index };
        // Add source to state
        this.getList(sceneId)[source.id].set(visualSource);
        // Add stream to studio-merger
        StudioStage.addSource(visualSource);
        this.addSourcesByIndex();
    }
    addSourcesByIndex() {
        const list = this.getList().peek();
        if (list) {
            const visualSources = Object.values(list).filter(source => source.sourceType === SourceType.VISUAL);
            StudioStage.addList(visualSources);
        }
    }
    addVisualSourcePossibleSound(index, source) {
        const { source: baseSource, sourceKind } = source || {};
        const { sourceLabel, sourceName, isRemote, isRetrieval, settings } = source;
        const audioOpt = {
            mute: false,
            volume: 10,
            isRemote,
            isRetrieval,
            sourceLabel,
            sourceName,
            sourceKind,
            settings,
            sourceType: SourceType.SOUND,
        };
        // Does media-stream have audio track?
        if (baseSource instanceof MediaStream && baseSource.getAudioTracks().length) {
            const mediaStream = baseSource.clone();
            // Remove video track from stream
            mediaStream.removeTrack(mediaStream.getVideoTracks()[0]);
            // Add MediaStream audio track
            this.addSoundSource(index + 1, {
                ...audioOpt,
                id: mediaStream.id,
                source: mediaStream,
            });
        }
        // Does video-file have audio track?
        if (baseSource && sourceKind === SourceKind.VIDEO_FILE) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const stream = baseSource.captureStream();
            if (stream.getAudioTracks().length) {
                // Add MediaStream audio track
                this.addSoundSource(index + 1, {
                    ...audioOpt,
                    id: nanoid(),
                    source: baseSource,
                });
            }
            stream.getTracks().forEach((track) => track.stop());
        }
    }
    async addSource(source, sceneId) {
        const list = this.getList().peek();
        const index = source.index || Object.keys(list || {}).length;
        // Add visual source
        if (source.sourceType === SourceType.VISUAL) {
            await this.addVisualSource(index, source, sceneId);
            // Check/Add visual source audio track
            this.addVisualSourcePossibleSound(index, source);
        }
        // Add Sound source
        else if (source.sourceType === SourceType.SOUND) {
            this.addSoundSource(index, source, sceneId);
        }
        if (!sceneId)
            this.activate(source.id);
    }
    async mapSource(source) {
        try {
            let stream = null;
            switch (source.sourceKind) {
                // Display Captures
                case SourceKind.DISPLAY_CAPTURE:
                    stream = await LocalStream.getDisplay(configs.studio.getScreenCaptureConstraint("monitor"));
                    break;
                case SourceKind.WINDOW_CAPTURE:
                    stream = await LocalStream.getDisplay(configs.studio.getScreenCaptureConstraint("window"));
                    break;
                case SourceKind.BROWSER_CAPTURE:
                    stream = await LocalStream.getDisplay(configs.studio.getScreenCaptureConstraint("browser"));
                    break;
                // Files Loaders
                case SourceKind.IMAGE_FILE:
                    stream = (await LocalStream.loadFile(SourceKind.IMAGE_FILE)).source;
                    break;
                case SourceKind.VIDEO_FILE:
                    stream = (await LocalStream.loadFile(SourceKind.VIDEO_FILE)).source;
                    break;
                case SourceKind.AUDIO_FILE:
                    stream = (await LocalStream.loadFile(SourceKind.AUDIO_FILE)).source;
                    break;
                default:
                    break;
            }
            // Add mapped source
            await this.addSource({ ...source, source: stream, isRetrieval: false });
            // Remove retrieval frame
            // StudioStage.removeSource(source.id);
        }
        catch (err) {
            logger.error("MapSource:", err);
        }
    }
    deleteSource(sourceId, sceneId) {
        const activeSceneId = selectActiveSceneId().peek();
        const { source, sourceType } = this.getSource(sourceId, sceneId).peek();
        // First activate next source
        if (!sceneId || sceneId === activeSceneId) {
            this.activateNextPossibleSource(sourceId);
        }
        // Disable MediaStream Tracks
        if (source instanceof MediaStream) {
            source.getTracks().forEach((track) => {
                track.enabled = false;
                track.stop();
            });
        }
        // If it's not a source of the active scene, it means it doesn't exist inside stage
        if ((!sceneId || sceneId === activeSceneId) && sourceType === SourceType.VISUAL) {
            StudioStage.removeSource(sourceId);
        }
        if (sourceType === SourceType.SOUND)
            AudioMixer.instance.removeStream(sourceId);
        // Remove source from state
        this.getSource(sourceId, sceneId).delete();
        // Delete sceneId key from list if it contains with no source
        if (!Object.keys(this.getList(sceneId).peek()).length) {
            this.getList(sceneId).delete();
        }
        // Sort & reorder indexes
        this.sortSourcesIndexOrder();
    }
    switchSource(source) {
        const oldSource = this.getSource().peek();
        let newSource = { ...source, index: oldSource.index };
        if (source.sourceType === SourceType.VISUAL) {
            const { positionY, positionX } = oldSource;
            newSource = { ...newSource, positionY, positionX };
        }
        void this.addSource(newSource);
        this.deleteSource(oldSource.id);
    }
    updateSourceText(baseSource) {
        const { id, style, source, sourceLabel, sourceName } = baseSource;
        // Update source state
        this.getSource(id).assign({ style, source, sourceLabel, sourceName });
        // Update text on stage
        StudioStage.updateTextStyle(baseSource);
    }
    updateSourceShape(baseSource) {
        const { id, source, width: w } = baseSource;
        const [width, height] = source.name === ShapeName.LINE ? [w, 10] : [w, w];
        // Update source state
        this.getSource(id).assign({ source, width, height });
        // Update shape on stage
        StudioStage.updateShape({ ...baseSource, width, height });
    }
    duplicateSource(sourceId) {
        const list = this.getList().peek();
        const source = this.getSource(sourceId).peek();
        let newSource;
        let id = nanoid();
        // All Camera, Audio, Screen, and Peer media sources
        if (source.source instanceof MediaStream) {
            newSource = source.source.clone();
            id = newSource.id;
        }
        // Source Files
        if ([SourceKind.IMAGE_FILE, SourceKind.VIDEO_FILE, SourceKind.AUDIO_FILE].includes(source.sourceKind)) {
            newSource = source.source.cloneNode();
        }
        if (source.sourceKind === SourceKind.TEXT) {
            newSource = source.source;
        }
        void this.addSource({
            ...source,
            id,
            index: Object.keys(list || {}).length,
            source: newSource,
            sourceName: `${source.sourceName.replace(/\(copy\)/g, "").trim()} (copy)`,
        });
    }
    updateSourceSize(sourceId, width, height, updateStage = true) {
        // Update source size in state
        this.getSource(sourceId).assign({
            width: +width.toFixed(2),
            height: +height.toFixed(2),
        });
        if (updateStage) {
            StudioStage.updateSize(sourceId, +width.toFixed(2), +height.toFixed(2));
        }
    }
    updateSourcePosition(sourceId, x, y, updateStage = true) {
        const positionX = +x.toFixed(2);
        const positionY = +y.toFixed(2);
        // Update source positions in state
        this.getSource(sourceId).assign({ positionX, positionY });
        // If the change is from react, update the stage,
        // Otherwise it's coming from stage itself and no need to update the stage again
        if (updateStage) {
            StudioStage.updatePosition(sourceId, positionX, positionY);
        }
    }
    updateRotation(sourceId, degree) {
        this.getSource(sourceId).assign({ rotation: degree });
        StudioStage.rotate(sourceId, degree);
    }
    updateSourceFlip(sourceId, flipX, flipY) {
        // Update source positions in state
        this.getSource(sourceId).assign({ flipX, flipY });
        StudioStage.flip(sourceId, "x", flipX);
        StudioStage.flip(sourceId, "y", flipY);
    }
    setSourceFillCanvas(sourceId) {
        const { width, height } = StudioStage.app.screen;
        this.updateSourceSize(sourceId, width, height);
        this.updateSourcePosition(sourceId, 0, 0);
    }
    setSourceStretchToCanvas(sourceId) {
        const { positionY, height } = this.getSource(sourceId).peek();
        const { width } = StudioStage.app.screen;
        this.updateSourceSize(sourceId, width, height);
        this.updateSourcePosition(sourceId, 0, positionY);
    }
    updateSourceVolume(sourceId, volume) {
        this.getSource(sourceId).assign({ volume });
        // Change Audio volume in AudioMixer
        AudioMixer.instance.updateVolume(sourceId, volume);
    }
    muteSource(sourceId, mute) {
        const { volume } = this.getSource(sourceId).peek();
        // Set source mute
        this.getSource(sourceId).assign({ mute });
        // Mute source audio inside mixer
        AudioMixer.instance.updateVolume(sourceId, mute ? 0 : volume);
    }
    sortSourcesIndexOrder() {
        const list = this.getList().peek();
        if (list) {
            const newList = {};
            // Sort & reorder indexes
            Object.entries(list)
                .sort(([, a], [, b]) => a.index - b.index)
                .forEach((item, index) => {
                newList[item[1].id] = { ...item[1], index };
            });
            this.getList().set(newList);
            // Update stage
            Object.values(newList)
                .filter((source) => source.sourceType === SourceType.VISUAL)
                .map((source, index) => {
                StudioStage.updateSourceIndex(source.id, index);
            });
        }
    }
    updateSourceIndexOrder(sourceId, type) {
        const sources = this.getList().peek();
        const source = this.getSource(sourceId).peek();
        const sourceIndex = source.index;
        const [altId, altSource] = Object.entries(sources).find(([, x]) => {
            if (type === SourceReorder.FORWARD)
                return x.index === source.index + 1;
            if (type === SourceReorder.BACKWARD)
                return x.index === source.index - 1;
            if (type === SourceReorder.FRONT)
                return x.index === Object.keys(sources).length - 1;
            return x.index === 0;
        }) || [];
        if (altSource) {
            this.getSource(sourceId).index.set(altSource.index);
            this.getSource(altId).index.set(sourceIndex);
        }
        this.sortSourcesIndexOrder();
    }
    adjustSourcePosition(sourceId, action) {
        const source = this.getSource(sourceId).peek();
        const { width, height, positionX, positionY } = source;
        const { width: screenW, height: screenH } = StudioStage.app.screen;
        const [halfW, halfH] = [screenW / 2, screenH / 2];
        const [halfSW, halfSH] = [width / 2, height / 2];
        let [x, y] = [0, 0];
        switch (action) {
            case SourceAlignment.HORIZONTAL_LEFT:
                [x, y] = [0, positionY];
                break;
            case SourceAlignment.HORIZONTAL_RIGHT:
                [x, y] = [screenW - width, positionY];
                break;
            case SourceAlignment.HORIZONTAL_CENTER:
                [x, y] = [halfW - halfSW, positionY];
                break;
            case SourceAlignment.VERTICAL_TOP:
                [x, y] = [positionX, 0];
                break;
            case SourceAlignment.VERTICAL_BOTTOM:
                [x, y] = [positionX, screenH - height];
                break;
            case SourceAlignment.VERTICAL_CENTER:
                [x, y] = [positionX, halfH - halfSH];
                break;
            default:
                break;
        }
        this.updateSourcePosition(sourceId, x, y);
    }
    lockSource(sourceId, lock) {
        this.getSource(sourceId).assign({ lock });
        StudioStage.lock(sourceId, lock);
    }
    hideSource(sourceIds, hidden) {
        const action = (sourceId) => {
            const source = this.getSource(sourceId).peek();
            if (source?.sourceType == SourceType.VISUAL) {
                this.getSource(sourceId).assign({ hidden });
                StudioStage.hide(sourceId, hidden);
            }
        };
        if (typeof sourceIds === "string") {
            action(sourceIds);
        }
        else {
            const selectedSources = this.selected.get();
            selectedSources.forEach(id => action(id));
        }
    }
    updateSourceTransparency(sourceId, value) {
        const transparency = value > 100 ? 100 : value;
        this.getSource(sourceId).assign({ transparency });
        StudioStage.updateTransparency(sourceId, transparency);
    }
    updateSourceProp(sourceId, key, value) {
        this.getSource(sourceId).assign({ [key]: value });
    }
    seAddModalData(data) {
        this.addModalData.set(data);
    }
    setDeleteModalData(data) {
        this.deleteModalData.set(data);
    }
    setSelected(source) {
        if (typeof source === "string") {
            const idx = this.selected.get().findIndex(id => id == source);
            idx === -1 ? this.selected.push(source) : this.selected.splice(idx, 1);
        }
        else if (source?.length) {
            source.forEach(source => {
                const idx = this.selected.get().findIndex(id => id == source);
                if (idx === -1)
                    this.selected.push(source);
            });
        }
        else {
            this.selected.set(source);
        }
    }
}

export var SceneColor;
(function (SceneColor) {
    SceneColor["Gray"] = "#A1A1A1";
    SceneColor["Red"] = "#FF3535";
    SceneColor["Orange"] = "#FF8A00";
    SceneColor["Yellow"] = "#F8E219";
    SceneColor["Lime"] = "#9ED600";
    SceneColor["Green"] = "#04C600";
    SceneColor["Aqua"] = "#44EFBC";
    SceneColor["Sky"] = "#00E6E6";
    SceneColor["Marine"] = "#00A1D4";
    SceneColor["Blue"] = "#2079FF";
    SceneColor["DarkBlue"] = "#0025E9";
    SceneColor["Violet"] = "#8000FF";
    SceneColor["Purple"] = "#B33DFB";
    SceneColor["Magenta"] = "#E51BAC";
    SceneColor["Maroon"] = "#A7005A";
})(SceneColor || (SceneColor = {}));

import { LocalStream } from "@src/services/localStream";
import { activateScene, addStudioSource, createNewScene } from "@src/controller";
import { SourceKind, SourceType, } from "@src/domain/Studio/types";
import { StudioMixer, StudioScenes, StudioScreen, StudioSources, StudioStream } from "@src/domain/Studio";
export class Studio {
    constructor(data) {
        Object.defineProperty(this, "screen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "scenes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sources", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mixer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "stream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "savedState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.screen = new StudioScreen();
        this.scenes = new StudioScenes();
        this.sources = new StudioSources();
        this.mixer = new StudioMixer();
        this.stream = new StudioStream();
        if (data)
            this.savedState = data;
    }
    restoreStudio() {
        const studio = this.savedState?.peek();
        if (!studio)
            return;
        if (studio?.screen)
            this.restoreScreen(studio.screen);
        if (studio?.mixer)
            this.restoreMixer(studio.mixer);
        if (studio?.scenes)
            this.restoreScenes(studio.scenes);
        if (studio?.sources)
            this.restoreSources(studio.sources);
        this.savedState.set(null);
    }
    restoreScreen(screen) {
        Object.keys(this.screen.peek()).forEach(k => {
            const key = k;
            if (Object.keys(screen).includes(key)) {
                this.screen[key].set(screen[key]);
            }
        });
    }
    restoreMixer(mixer) {
        Object.keys(this.mixer.peek()).forEach(k => {
            const key = k;
            if (Object.keys(mixer).includes(key)) {
                this.mixer[key].set(mixer[key]);
            }
        });
    }
    restoreScenes(scenes) {
        const defaultScenes = this.scenes.peek();
        const scenesList = scenes?.list ? Object.keys(scenes.list) : [];
        // Add default keys
        Object.keys(defaultScenes).forEach(k => {
            const key = k;
            if (key !== "list" && Object.keys(scenes).includes(key)) {
                this.scenes[key].set(scenes[key]);
            }
        });
        if (scenesList.length) {
            // Add scene list
            scenesList.map((sceneId) => {
                createNewScene(scenes.list[sceneId]);
            });
        }
        // Activate the scene that was active before
        const activeScene = this.scenes.activeSceneId.peek();
        if (activeScene)
            activateScene(activeScene);
    }
    restoreSources(sources) {
        const defaultSources = this.sources.peek();
        const sceneList = sources?.list ? Object.keys(sources.list) : [];
        // Add default keys
        Object.keys(defaultSources).forEach(k => {
            const key = k;
            if (!["list", "isObjectPropertiesPanelOpen"].includes(key) && Object.keys(sources).includes(key)) {
                this.sources[key].set(sources[key]);
            }
        });
        // Add source list
        sceneList.map((sceneId) => {
            // Removed scene id if the sceneId doesn't exist inside scenes.list
            if (!Object.keys(this.scenes.list.peek()).includes(sceneId)) {
                delete sources.list[sceneId];
                return;
            }
            const sortedList = Object.values(sources.list[sceneId]).sort((a, b) => a.index - b.index);
            const mapSource = async (source) => {
                let baseSource = null;
                let isRetrieval = true;
                // If it's a remote source (participants video/audio/screen source),
                // Or, its videofile sound source,
                // Skip and delete the source from storage
                if (source.isRemote ||
                    (source.sourceKind === SourceKind.VIDEO_FILE && source.sourceType === SourceType.SOUND)) {
                    delete sources.list[sceneId][source.id];
                    return;
                }
                if (source.sourceKind === SourceKind.AUDIO_INPUT || source.sourceKind === SourceKind.VIDEO_INPUT) {
                    const type = ["audio", "video"][+(source.sourceType === SourceType.VISUAL)];
                    isRetrieval = false;
                    baseSource = await LocalStream.getMediaTrack(type, {
                        deviceId: source.settings.deviceId,
                    });
                }
                else if (source.sourceKind === SourceKind.TEXT || source.sourceKind === SourceKind.SHAPE) {
                    isRetrieval = false;
                    baseSource = source.source;
                }
                addStudioSource({
                    ...source,
                    isRetrieval,
                    source: baseSource,
                }, sceneId // Add to sceneId
                );
                if (sortedList.length)
                    void mapSource(sortedList.shift());
            };
            if (sortedList.length)
                void mapSource(sortedList.shift());
        });
    }
}

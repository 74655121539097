import configs from "@src/configs/index";
const screenOutputs = {
    // 180: { width: 320, height: 180, fps: 30 },
    "360": { width: 640, height: 360, fps: 25 },
    "480": { width: 854, height: 480, fps: 25 },
    "540": { width: 960, height: 540, fps: 25 },
    "576": { width: 1024, height: 576, fps: 30 },
    "720": { width: 1280, height: 720, fps: 40 },
    "768": { width: 1366, height: 768, fps: 40 },
    "1080": { width: 1920, height: 1080, fps: 40 },
    "1440": { width: 2560, height: 1440, fps: 40 },
};
export default {
    defaultOutput: screenOutputs[1080],
    screenOutputs,
    screenResolutions: Object.keys(screenOutputs).map((key) => ({
        label: `${screenOutputs[key].width} × ${screenOutputs[key].height}`,
        value: `${screenOutputs[key].width}*${screenOutputs[key].height}`,
    })),
    source: {
        defaultWidth: 600,
        defaultPositionX: 100,
        defaultPositionY: 100,
        defaultFont: "Open Sans",
        fonts: [
            "Roboto",
            "Open Sans",
            "Lato",
            "Noto Sans JP",
            "Montserrat",
            "Roboto Condensed",
            "Oswald",
            "Poppins",
            "Raleway",
            "Slabo 27px",
            "PT Sans",
            "Noto Sans",
            "Lora",
            "Merriweather",
            "Ubuntu",
        ],
        // textStyle: {}
    },
    getScreenCaptureConstraint(displaySurface) {
        return {
            audio: true,
            video: {
                width: configs.studio.defaultOutput.width,
                height: configs.studio.defaultOutput.height,
                frameRate: configs.studio.defaultOutput.fps,
                displaySurface,
            },
        };
    },
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AttentionFilledIcon, MicOffOutlinedIcon, MicOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { useState } from "react";
import { Button, Text } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { ControllerButtonSizes } from "../types";
import { classNameFactory } from "@src/utils/dom";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectAudioInputError, selectIsMicOn, selectRoomUISettings } from "@src/selectors";
import { toggleMicOn } from "@src/controller/userMedia";
import { PermissionModal } from "@src/components/PermissionModal";
import { MediaInputErrors } from "@src/domain/SelfStream/types";
import "./micController.scss";
const cn = classNameFactory("mic-controller");
export const MicController = ({ withText = false, size = ControllerButtonSizes.medium }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation(["room"]);
    const [isOpen, setOpen] = useState(false);
    const audioInputError = useSubscribe(selectAudioInputError());
    const isStreamControllersDisabled = useSubscribe(selectRoomUISettings().isStreamControllersDisabled);
    const isMicOn = useSubscribe(selectIsMicOn());
    const changeMicOn = async () => {
        if (audioInputError == MediaInputErrors.NotAllowedError) {
            setOpen(true);
        }
        setIsLoading(true);
        await toggleMicOn(!isMicOn);
        setIsLoading(false);
    };
    const AttentionSize = size === ControllerButtonSizes.big ? 20 : 16;
    return (_jsxs("div", { className: cn(""), children: [_jsxs("div", { className: cn("buttons"), children: [audioInputError && (_jsx(AttentionFilledIcon, { width: AttentionSize, height: AttentionSize, className: cn("attention-icon") })), _jsx(Button, { v2: true, onClick: changeMicOn, size: size, type: isMicOn ? "neutral-inverted" : "danger", startIcon: isMicOn ? _jsx(MicOutlinedIcon, { width: 24, height: 24 }) : _jsx(MicOffOutlinedIcon, { width: 24, height: 24 }), round: true, disabled: isLoading || isStreamControllersDisabled })] }), withText && (_jsx(Text, { size: "body-medium", fontWeight: "regular", children: isMicOn ? t("mic-on") : t("mic-off") })), _jsx(PermissionModal, { mic: !!audioInputError, open: isOpen, onClose: () => setOpen(false) })] }));
};

import { state } from "@src/state";
export const selectIsScenesOpen = () => state.meeting.studio.scenes.isPanelOpen;
export const selectIsCreateScenesModalOpen = () => state.meeting.studio.scenes.isCreateSceneModalOpen;
export const selectStudioScenes = () => state.meeting.studio.scenes.list;
export const selectActiveSceneId = () => state.meeting.studio.scenes.activeSceneId;
export const selectStudioActiveScene = () => {
    const activeSceneId = state.meeting.studio.scenes.activeSceneId.get();
    return state.meeting.studio.scenes.list[activeSceneId];
};
export const selectIsGridView = () => state.meeting.studio.scenes.isGridView;
export const selectSceneDeleteModalData = () => state.meeting.studio.scenes.deleteModalData;

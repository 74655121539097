import { jsx as _jsx } from "react/jsx-runtime";
import "reflect-metadata";
import { Router as ReactRouter } from "react-router";
import * as Sentry from "@sentry/react";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import pkgInfo from "../package.json";
import { handleInit } from "./controller";
import { container } from "@src/ioc";
import { Router } from "@src/routes";
import { history } from "@src/services/history";
import { DependencyInjectionProvider } from "@src/context/DependencyInjection";
import configs from "@src/configs";
import { ToasterProvider } from "@src/components/Toaster";
import "@src/theme/main.scss";
import "@ting/ting-ui-components/dist/ting-ui-components.min.css";
handleInit();
if (configs.app.sentryDNS && process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn: configs.app.sentryDNS,
        release: `meeting@${pkgInfo.version}`,
        environment: process.env.NODE_ENV,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
            }),
            new CaptureConsoleIntegration({
                levels: ["warn", "error"],
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
const App = () => {
    return (_jsx(Sentry.ErrorBoundary, { fallback: _jsx("h1", { children: "Something went wrong!" }), children: _jsx(DependencyInjectionProvider, { container: container, children: _jsx(ToasterProvider, { children: _jsx(ReactRouter, { history: history, children: _jsx(Router, {}) }) }) }) }));
};
export default App;

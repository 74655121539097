var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { inject, injectable } from "inversify";
import { SpeakerService } from "../SpeakerService";
import { RaisedHandService } from "../RaiseHandService/RaiseHandService";
import { history } from "@src/services/history";
import { selectMeeting, selectRoom, selectRoomCreatedTime, selectRoomMode, selectRoomPeer, selectRoomPublicId, selectRoomSelfPeer, selectRoomSlug, selectRoomTitle, selectRoomType, selectSelfStream, selectStreamSettings, } from "@src/selectors";
import { TYPES } from "@src/ioc.type";
import { Room, RoomStatus, RoomType } from "@src/domain/Room";
import { Peer, PeerRoles } from "@src/domain/Peer";
import { Protocols, Code, } from "@src/services/PeerCommunicator";
import { DCMessageType, PeerConnectionEvents } from "@src/domain/RTCConnection";
import { DCMessage } from "@src/domain/RTCConnection/DCMessage";
import { resetToInitialState } from "@src/controller";
import { routePaths } from "@src/routes/routes";
import { AudioType, playAudioNotification } from "@src/services/AudioNotifier";
import { Timestamp } from "@src/services/PeerCommunicator/messages/google/protobuf/timestamp";
let RoomService = class RoomService {
    constructor(rtcConnectionService, peerCommunicator, peerService, roomsApiService, chatService, speakerService, candidateService, raisedHandService) {
        Object.defineProperty(this, "_peerCommunicator", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_rtcConnectionService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_peerService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_roomsApiService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_chatService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_speakerService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_raiseHandService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_candidateService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this._peerCommunicator = peerCommunicator;
        this._rtcConnectionService = rtcConnectionService;
        this._peerService = peerService;
        this._roomsApiService = roomsApiService;
        this._chatService = chatService;
        this._speakerService = speakerService;
        this._candidateService = candidateService;
        this._raiseHandService = raisedHandService;
        this.init();
    }
    closeRTCConnection() {
        throw new Error("Method not implemented.");
    }
    init() {
        this._peerCommunicator.on(Protocols.join, this.handleJoinRoomRequest.bind(this));
        this._peerCommunicator.on(Protocols.compactRoomInfo, this.handleCompactRoomInfoRequest.bind(this));
        this._raiseHandService.init();
    }
    // TODO: use peek for selectors?
    getRoom() {
        return selectRoom().get();
    }
    getRoomType() {
        return selectRoomType().get();
    }
    getRoomMode() {
        return selectRoomMode().get();
    }
    getRoomTitle() {
        return selectRoomTitle().get();
    }
    getRoomSlug() {
        return selectRoomSlug().get();
    }
    getRoomPublicId() {
        return selectRoomPublicId().get();
    }
    getRoomCreatedAt() {
        return selectRoomCreatedTime().get();
    }
    createRoom({ entryMic, entryCam, publicId, ...rest }) {
        const room = new Room({
            ...rest,
            publicId,
            entryState: {
                mic: entryMic,
                cam: entryCam,
            },
        });
        // TODO: how to manage this
        selectMeeting().setRoom(room);
    }
    createAndSetSelfPeer(name, isCreating) {
        const selfStream = selectSelfStream();
        const selfStreamSettings = selectStreamSettings();
        const peer = this._peerService.createSelfPeer(name, this._peerCommunicator.peerId, isCreating ? PeerRoles.SUPER_NODE : PeerRoles.SIMPLE);
        // TODO: how to manage this
        selectRoom().setSelfPeer(peer);
        // TODO: we are saving selfStream and selfStreamSettings in two places...
        selectRoomSelfPeer().setSelfStream(selfStream.peek());
        selectRoomSelfPeer().setPeerStreamSetting(selfStreamSettings.peek());
        this.initiateRtcManager();
    }
    startRoom() {
        const selfStream = selectSelfStream().peek();
        const roomObservable = selectRoom();
        roomObservable.changeRoomStatus(RoomStatus.active);
        // TODO: it would be better to do this in selfStreamService
        this._rtcConnectionService.setSendingStream(selfStream.stream);
        this._speakerService.startTracking();
    }
    async authorizePeerIdToJoin(peerId) {
        const roomType = this.getRoomType();
        const slug = this.getRoomSlug();
        if (roomType === RoomType.scheduled) {
            const updatedRoomRes = await this._roomsApiService.getRoom(slug);
            if (!updatedRoomRes.peers.includes(peerId))
                return false;
        }
        return true;
    }
    initiateRtcManager() {
        this._rtcConnectionService.init();
        // TODO: is this the best place for this?
        this._rtcConnectionService.on(PeerConnectionEvents.ReceivedDCMessage, (message) => {
            this._chatService.onMessage(message);
            switch (message.type) {
                case DCMessageType.PEER_JOINED:
                    this.handleReceivedNewPeer(message.payload);
                    break;
                case DCMessageType.PEER_STREAM_STATUS: {
                    const { peerId, isCamOn, isMicOn } = message.payload;
                    if (isCamOn !== undefined)
                        selectRoomPeer(peerId).toggleCam(isCamOn);
                    if (isMicOn !== undefined)
                        selectRoomPeer(peerId).toggleMic(isMicOn);
                    break;
                }
                case DCMessageType.PEER_SCREEN_SHARE_STATUS: {
                    const { peerId, enabled } = message.payload;
                    selectRoomPeer(peerId).toggleScreen(enabled);
                    break;
                }
                case DCMessageType.PEER_LEFT: {
                    const { peerId } = message.payload;
                    this._peerService.removePeer(peerId);
                    break;
                }
                case DCMessageType.PEER_KICK: {
                    const { peerId } = message.payload;
                    const selfPeer = this._peerService.getSelfPeer();
                    if (selfPeer.peerId === peerId) {
                        history.push(routePaths.HOME());
                        selectRoom().changeRoomStatus(RoomStatus.leaving);
                        resetToInitialState();
                    }
                    else {
                        this._peerService.removePeer(peerId);
                    }
                    break;
                }
                case DCMessageType.CALL_ENDED: {
                    history.push(routePaths.HOME());
                    selectRoom().changeRoomStatus(RoomStatus.leaving);
                    resetToInitialState();
                    break;
                }
            }
        });
    }
    handleReceivedNewPeer(newPeerObject) {
        const newPeer = Peer.fromObject(newPeerObject);
        playAudioNotification(AudioType.PeerJoined);
        this._peerService.addPeer(newPeer);
    }
    async joinRoom(name, peerStreamSettings, corepassId, avatarURL) {
        const publicId = this.getRoomPublicId();
        const room = selectRoom().peek();
        if (room.type === RoomType.scheduled) {
            // Let server know we want to join this room
            this._roomsApiService.joinRoom(room.slug, this._peerCommunicator.peerId);
        }
        const joinReq = {
            name,
            corepassId,
            avatarURL,
            peerStreamSettings: peerStreamSettings,
        };
        const response = await this._peerCommunicator.send(publicId, joinReq, Protocols.join);
        let supernodePeer;
        response.peers?.forEach(peer => {
            const newPeer = Peer.fromObject({
                peerId: peer.peerId,
                avatarURL: peer.avatarURL,
                corepassId: peer.corepassId,
                name: peer.name,
                peerStreamSettings: peer.peerStreamSettings,
                role: peer.role,
                raisedHandAt: peer.raisedHandAt && Number(peer.raisedHandAt),
            });
            if (newPeer.role === PeerRoles.SUPER_NODE)
                supernodePeer = newPeer;
            this._peerService.addPeer(newPeer);
            this._peerService.addPeer(newPeer);
            selectRoom().changeRoomStatus(RoomStatus.active);
        });
        // TODO: it would be better to do this in selfStreamService
        this.startRoom();
        this._rtcConnectionService.addConnectionTo(supernodePeer);
        selectRoom().changeMode(response.mode);
        selectRoom().setCreatedAt(Timestamp.toDate(response.createdAt));
    }
    async handleJoinRoomRequest(newPeerId, data) {
        const title = this.getRoomTitle();
        const mode = this.getRoomMode();
        const createdAt = Timestamp.fromDate(this.getRoomCreatedAt());
        const selfPeer = this._peerService.getSelfPeer();
        const peersArray = this._peerService.getRoomPeersArray();
        const room = this.getRoom();
        const authorized = await this.authorizePeerIdToJoin(newPeerId);
        if (!authorized) {
            //TODO: use i18-n
            throw { code: Code.UNAUTHENTICATED, message: "You are not authorized to join this room" };
        }
        if (room.status !== RoomStatus.active) {
            //TODO: use i18-n
            throw { code: Code.UNAVAILABLE, message: "Room is not ready at this moment" };
        }
        const peers = peersArray.map(p => {
            const op = p.toObject();
            return Object.assign(op, { raisedHandAt: op.raisedHandAt ? BigInt(op.raisedHandAt) : null });
        });
        const newPeer = Peer.fromObject({
            name: data.name,
            avatarURL: data.avatarURL,
            corepassId: data.corepassId,
            peerStreamSettings: data.peerStreamSettings,
            peerId: newPeerId,
            role: PeerRoles.SIMPLE,
        });
        const peerJoinedMessage = new DCMessage({
            from: { peerId: selfPeer.peerId, name: selfPeer.name },
            to: "all",
            type: DCMessageType.PEER_JOINED,
            payload: newPeer.toObject(),
        });
        this._rtcConnectionService.sendMessage(peerJoinedMessage);
        this._rtcConnectionService.addConnectionTo(newPeer);
        this.handleReceivedNewPeer(newPeer);
        return {
            peers,
            title,
            mode,
            createdAt,
        };
    }
    // Todo find a better place this
    sendPeerStreamStatusMessage({ isMicOn, isCamOn }) {
        const peer = selectRoomSelfPeer().peek();
        if (!peer)
            return;
        const { peerId, name } = peer;
        const peerStreamStatusMessage = new DCMessage({
            from: { peerId, name },
            to: "all",
            type: DCMessageType.PEER_STREAM_STATUS,
            payload: {
                peerId,
                ...(isMicOn !== undefined ? { isMicOn } : { isCamOn }),
            },
        });
        this._rtcConnectionService.sendMessage(peerStreamStatusMessage);
    }
    // Todo find a better place this
    addScreenTracks(stream) {
        const selfPeer = this._peerService.getSelfPeer();
        const peerJoinedMessage = new DCMessage({
            from: { peerId: selfPeer.peerId, name: selfPeer.name },
            to: "all",
            type: DCMessageType.PEER_SCREEN_SHARE_STATUS,
            payload: { peerId: selfPeer.peerId, enabled: true },
        });
        this._rtcConnectionService.sendMessage(peerJoinedMessage);
        this._rtcConnectionService.addScreenTracksToPCs(stream);
    }
    // Todo find a better place this
    removeScreenTracks(stream) {
        const selfPeer = this._peerService.getSelfPeer();
        const peerJoinedMessage = new DCMessage({
            from: { peerId: selfPeer.peerId, name: selfPeer.name },
            to: "all",
            type: DCMessageType.PEER_SCREEN_SHARE_STATUS,
            payload: { peerId: selfPeer.peerId, enabled: false },
        });
        this._rtcConnectionService.sendMessage(peerJoinedMessage);
        this._rtcConnectionService.removeScreenTracksFromPCs(stream);
    }
    handleCompactRoomInfoRequest(senderPeerId, data) {
        const room = this.getRoom();
        const peersArray = this._peerService.getRoomPeersArray();
        if (room.status !== RoomStatus.active) {
            throw { code: Code.UNAVAILABLE, message: "Room is not ready at this moment" };
        }
        const compactPeers = peersArray.map(peer => {
            return {
                peerId: peer.peerId,
                name: peer.name,
                avatarURL: peer.avatarURL,
                corepassId: peer.corepassId,
                role: peer.role,
            };
        });
        return {
            title: room.title,
            mode: room.mode,
            compactPeers,
        };
    }
};
RoomService = __decorate([
    injectable(),
    __param(0, inject(TYPES.RTCConnectionService)),
    __param(1, inject(TYPES.PeerCommunicator)),
    __param(2, inject(TYPES.PeerService)),
    __param(3, inject(TYPES.RoomsApiService)),
    __param(4, inject(TYPES.ChatService)),
    __param(5, inject(TYPES.SpeakerService)),
    __param(6, inject(TYPES.CandidateService)),
    __param(7, inject(TYPES.RaiseHandService)),
    __metadata("design:paramtypes", [Object, Object, Object, Object, Object, SpeakerService, Object, RaisedHandService])
], RoomService);
export { RoomService };

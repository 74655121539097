import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Tag } from "@ting/ting-ui-components";
import { soundLevel } from "./utils/soundLevel";
import { AudioMixer } from "@src/services/StudioService";
import { classNameFactory } from "@src/utils/dom";
import "./audioVolumeMeter.scss";
const cn = classNameFactory("audio-volume");
export const AudioVolumeMeter = props => {
    const [volumeInDecibels, setVolumeInDecibels] = useState(0);
    const activeVolumeMeterRef = useRef(null);
    const inactiveVolumeMeterRef = useRef(null);
    const handleSliderChange = (event) => props.onVolumeChanged(Number(event.target.value));
    useEffect(() => {
        let analyser;
        let audioData;
        let num;
        if (props.type === "STUDIO") {
            const sourceNode = AudioMixer.instance.getMediaStreamSource(props.streamId);
            analyser = sourceNode?.analyser;
            audioData = sourceNode?.audioData;
        }
        else {
            analyser = props.analyzerFilter?.analyser;
            audioData = props.analyzerFilter?.audioData;
        }
        const visualize = () => {
            if (analyser) {
                analyser.getByteFrequencyData(audioData);
                const min = 1;
                const max = 480;
                const segment = audioData.slice(0, 64);
                const sum = segment.reduce((a, b) => a + b);
                const average = sum / segment.length;
                let width = (1.5 * average * props.volume) / 100;
                width = Math.max(min, Math.min(max, width));
                const inactiveWidth = (width / 300) * 100;
                if (inactiveVolumeMeterRef?.current && activeVolumeMeterRef?.current) {
                    activeVolumeMeterRef.current.style.width = `${inactiveWidth}%`;
                }
                if (props.type === "STUDIO") {
                    setVolumeInDecibels(AudioMixer.instance.getAudioVolumeInDecibels(props.streamId));
                }
                else {
                    setVolumeInDecibels(props.analyzerFilter?.getAudioVolumeInDecibels());
                }
                num = requestAnimationFrame(visualize);
            }
        };
        if (audioData && analyser) {
            if (!props.disabled) {
                visualize();
                if (props.type === "STUDIO") {
                    AudioMixer.instance.getAudioContext().resume();
                }
            }
            else {
                if (num)
                    cancelAnimationFrame(num);
            }
        }
        return () => {
            if (num)
                cancelAnimationFrame(num);
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
    }, [props.type, props.volume, props.streamId, props.analyzerFilter, props.disabled]);
    return (_jsxs("div", { className: cn(""), children: [_jsxs("div", { className: cn("bars"), children: [_jsxs("div", { className: cn("meter"), children: [_jsx("span", { className: cn("meter-active"), ref: activeVolumeMeterRef, style: props.disabled ? { width: 0 } : {} }), _jsx("span", { className: cn("meter-inactive"), ref: inactiveVolumeMeterRef })] }), _jsx("div", { className: cn("slider"), children: _jsx("input", { type: "range", min: "0", max: "100", step: "1", width: "100%", value: props.volume, onChange: handleSliderChange, disabled: props.disabled }) })] }), _jsxs("div", { className: cn("tags"), children: [_jsx(Tag, { size: "small", children: _jsx("span", { className: `${cn("tag")} ${soundLevel(volumeInDecibels)}`, children: props.disabled ? "--" : volumeInDecibels }) }), _jsx(Tag, { size: "small", children: props.disabled ? "--" : props.volume })] })] }));
};

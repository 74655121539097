export default {
    minFrameSize: process.env.DEVICE === "mobile"
        ? {
            width: 167,
            height: 94,
        }
        : {
            width: 202,
            height: 114,
        },
    borderSize: 8,
    minSpeakingIntensity: 5,
    videoAspectRatio: 16 / 9,
};

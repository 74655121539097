import { Graphics, Sprite } from "pixi.js";
import { ShapeName } from "@src/domain/Studio/types";
export const StageShape = (source, renderer) => {
    const { width, height, source: { name, color, strokeWidth, strokeColor, points = 5, innerRadius = 0, rotation = 0 }, } = source;
    // Create source frame
    const frame = new Graphics({ label: "frame" })
        .roundRect(0, 0, width, height, 0)
        .stroke({ width: 6, color: "#ff9920", alpha: 1 });
    // Create shape background texture
    const shape = new Graphics({ label: "shape" });
    switch (name) {
        case ShapeName.LINE:
            shape.moveTo(0, 0).lineTo(width, 0).stroke({ width: height, color: color });
            return { sprite: shape, frame, width, height };
        case ShapeName.CIRCLE:
            shape.circle(0, 0, width);
            break;
        case ShapeName.STAR:
            shape.star(0, 0, points, width, innerRadius, rotation);
            break;
        case ShapeName.TRIANGLE:
            shape.star(0, 0, 3, width, 0, rotation);
            break;
        case ShapeName.RECTANGLE:
        default:
            shape.roundRect(0, 0, width, height, 0);
    }
    shape.fill(color).stroke({ width: strokeWidth, color: strokeColor, alpha: 1 });
    // Create background sprite
    const sprite = new Sprite({
        texture: renderer.generateTexture(shape),
        width,
        height,
        eventMode: "static",
    });
    return { sprite, frame, width, height };
};

import { jsx as _jsx } from "react/jsx-runtime";
import { lazy, Suspense } from "react";
export const lazyLoad = (importFunc, selectorFunc, opts = { fallback: null }) => {
    let lazyFactory = importFunc;
    if (selectorFunc) {
        lazyFactory = () => importFunc().then(module => ({ default: selectorFunc(module) }));
    }
    const LazyComponent = lazy(lazyFactory);
    return (props) => (_jsx(Suspense, { fallback: opts.fallback, children: _jsx(LazyComponent, { ...props }) }));
};

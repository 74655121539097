import { jsx as _jsx } from "react/jsx-runtime";
import { Loading } from "@ting/ting-ui-components";
import { Home } from "./Home";
import { AuthComponent } from "./Auth";
import { CreateRoom } from "./CreateRoom";
import { Lobby } from "./Lobby";
import { JoinRoom } from "./JoinRoom";
import configs from "@src/configs";
import { HomeLayout, EmptyLayout } from "@src/layout";
import { lazyLoad } from "@src/utils/lazyLoad";
const Room = lazyLoad(() => import("./Room"), module => module.Room, {
    fallback: _jsx(Loading, { fullHeight: true }),
});
const Studio = lazyLoad(() => import("./Studio"), module => module.Studio, {
    fallback: _jsx(Loading, { fullHeight: true }),
});
const MeetingLayout = lazyLoad(() => import("@src/layout/MeetingLayout"), module => module.MeetingLayout, {
    fallback: _jsx(Loading, { fullHeight: true }),
});
// we never use strings when changing location whether with Link component
// or pushing to history. we always use routePaths so that there is always only one point of change
export const routePaths = {
    HOME: () => "/",
    ROOM: (roomId) => `/room/${roomId}`,
    STUDIO: (roomId) => `/room/${roomId}/studio`,
    LOBBY: (roomId) => `/room/${roomId}/lobby`,
    LOGIN_CALLBACK: () => configs.auth.loginCallbackPath,
    CREATE_ROOM: (type) => (type ? `/create-room/${type}` : "/create-room"),
    JOIN_ROOM: () => "/join-room",
};
export const routes = [
    {
        layout: EmptyLayout,
        name: "home",
        routes: [
            {
                title: "home",
                path: routePaths.HOME(),
                component: Home,
            },
        ],
    },
    {
        layout: HomeLayout,
        name: "create-and-join",
        routes: [
            {
                title: "create-instant-meeting",
                path: routePaths.CREATE_ROOM(),
                component: CreateRoom,
            },
            {
                title: "create-schedule-meeting",
                path: routePaths.CREATE_ROOM(":type"),
                component: CreateRoom,
            },
            {
                title: "lobby",
                path: routePaths.LOBBY(":roomId"),
                component: Lobby,
            },
            {
                title: "join-instant-meeting",
                path: routePaths.JOIN_ROOM(),
                component: JoinRoom,
            },
        ],
    },
    {
        layout: MeetingLayout,
        name: "meeting",
        routes: [
            {
                title: "meeting",
                path: routePaths.ROOM(":roomId"),
                component: Room,
            },
            {
                title: "studio",
                path: routePaths.STUDIO(":roomId"),
                component: Studio,
            },
        ],
    },
    {
        layout: EmptyLayout,
        name: "auth",
        routes: [
            {
                path: routePaths.LOGIN_CALLBACK(),
                component: AuthComponent,
                title: "login",
            },
        ],
    },
];

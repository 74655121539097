export class AudioMixer {
    // private appended = false;
    constructor() {
        Object.defineProperty(this, "audioContext", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "videoSyncDelayNode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "audioDestination", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "list", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        this.audioContext = new AudioContext();
        this.audioDestination = this.audioContext.createMediaStreamDestination();
        this.videoSyncDelayNode = this.audioContext.createDelay(5.0); // delay node for video sync
        this.videoSyncDelayNode.connect(this.audioDestination);
        // HACK for wowza #7, #10
        const gain = this.audioContext.createGain(); // gain node prevents quality drop
        const constantAudioNode = this.audioContext.createConstantSource();
        gain.gain.value = 0;
        constantAudioNode.start();
        constantAudioNode.connect(gain);
        gain.connect(this.videoSyncDelayNode);
        // stop browser from throttling timers by playing almost-silent audio
        const source = this.audioContext.createConstantSource();
        const gainNode = this.audioContext.createGain();
        gainNode.gain.value = 0.001; // required to prevent popping on start
        source.connect(gainNode);
        gainNode.connect(this.audioContext.destination);
        source.start();
    }
    // private attachToBody() {
    //   const audio = document.createElement("audio");
    //   audio.srcObject = this.audioDestination.stream;
    //   audio.autoplay = true;
    //   void audio.play();
    //   document.body.appendChild(audio);
    // }
    getAudioContext() {
        return this.audioContext;
    }
    addAudioSource(source) {
        if (!(source.source instanceof MediaStream) && !(source.source instanceof HTMLMediaElement))
            return null;
        const foundedStreamNode = this.list.get(source.id);
        if (foundedStreamNode !== undefined) {
            return foundedStreamNode;
        }
        const audioSource = source.source instanceof MediaStream
            ? this.audioContext.createMediaStreamSource(source.source)
            : this.audioContext.createMediaElementSource(source.source);
        const audioOutput = this.audioContext.createGain();
        const gainNode = audioSource.context.createGain();
        audioOutput.gain.value = 1;
        gainNode.gain.setValueAtTime(source.volume / 100, audioSource.context.currentTime);
        audioSource.connect(gainNode);
        gainNode.connect(audioOutput);
        audioOutput.connect(this.videoSyncDelayNode);
        const node = {};
        node.mediaStream = audioSource;
        node.analyser = this.audioContext.createAnalyser();
        node.analyser.fftSize = 256;
        node.audioData = new Uint8Array(node.analyser.frequencyBinCount);
        node.gainNode = gainNode;
        node.mediaStream.connect(node.analyser);
        this.list.set(source.id, node);
        // if (!this.appended) {
        //   this.appended = true;
        //   this.attachToBody();
        // }
    }
    getAudioVolumeInDecibels(streamId) {
        const node = this.list.get(streamId);
        if (node) {
            node.analyser.getByteFrequencyData(node.audioData);
            // Calculate the average amplitude of the audio signal
            let sum = 0;
            node.audioData.forEach(value => {
                sum += value;
            });
            const averageAmplitude = sum / node.analyser.frequencyBinCount;
            // Convert the average amplitude to dB
            const val = Math.floor(20 * Math.log10(averageAmplitude / 255));
            return val === -Infinity ? 0 : val;
        }
        return null;
    }
    getMediaStreamSource(streamId) {
        return this.list.get(streamId);
    }
    removeStream(streamId) {
        const streamNode = this.list.get(streamId);
        if (streamNode) {
            if (streamNode.mediaStream instanceof MediaStreamAudioSourceNode) {
                streamNode.mediaStream.mediaStream.getTracks().forEach(track => {
                    track.stop();
                });
            }
            streamNode.mediaStream.disconnect(streamNode.analyser);
            streamNode.mediaStream = null;
            streamNode.analyser = null;
            streamNode.audioData = null;
            streamNode.gainNode = null;
            this.list.delete(streamId);
        }
    }
    updateVolume(streamId, volume) {
        const context = AudioMixer.instance.getAudioContext();
        const mediaSource = AudioMixer.instance.getMediaStreamSource(streamId);
        if (context && mediaSource) {
            mediaSource.gainNode.gain.setValueAtTime(volume / 100, context.currentTime);
        }
    }
}
Object.defineProperty(AudioMixer, "instance", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: new AudioMixer()
});

import { DCMessageBuilder } from "../helpers";
import { fetchCompactRoomInfo } from "./lobby";
import { RoomSecurity, RoomStatus } from "@src/domain/Room";
import { bindDependencies } from "@src/ioc";
import { history } from "@src/services/history";
import { TYPES } from "@src/ioc.type";
import { AuthService } from "@src/services/AuthService";
import { selectMeeting, selectMeetingUser, selectRoom, selectRoomSelfPeer, selectRoomTitle, selectRoomUISettings, selectStreamSettings, } from "@src/selectors";
import { routePaths } from "@src/routes/routes";
export function createPublicRoom({ title, mode, lobbyEnabled, locked, entryMic, entryCam, type, }) {
    return bindDependencies(async function (peerCommunicator, roomService, roomsApiService) {
        const publicId = peerCommunicator.peerId;
        const roomRes = await roomsApiService.createRoom({ type, publicId });
        const slug = roomRes.title;
        roomService.createRoom({
            status: RoomStatus.starting,
            title,
            mode,
            slug,
            lobbyEnabled,
            locked,
            entryMic,
            entryCam,
            publicId,
            type,
            security: RoomSecurity.public,
            createdAt: new Date(),
        });
        return slug;
    }, [TYPES.PeerCommunicator, TYPES.RoomService, TYPES.RoomsApiService]);
}
export function createScheduleMeeting(event) {
    return bindDependencies(async function (calender, calenderUtils) {
        const calenderEvent = calenderUtils.createCalendarEvent(event);
        const calendarEvent = await calender.createNewEvent(calenderEvent);
        return calendarEvent;
    }, [TYPES.Calender, TYPES.CalenderUtils]);
}
export function startRoom(name, meetingName) {
    return bindDependencies(async function (roomService) {
        if (meetingName !== selectRoomTitle().peek()) {
            //TODO: call rooms api to change it.
            selectRoom().changeTitle(meetingName);
        }
        const roomId = selectRoom().slug.peek();
        const roomRes = await fetchAndUpdateRoomInfo(roomId);
        await updateServerPublicId(roomRes);
        roomService.createAndSetSelfPeer(name, true);
        roomService.startRoom();
    }, [TYPES.RoomService, TYPES.RoomsApiService]);
}
export function fetchRoomInfo(roomId) {
    return bindDependencies(async function (roomsApiService) {
        return await roomsApiService.getRoom(roomId);
    }, [TYPES.RoomsApiService]);
}
export function updateRoomInfo(roomInfo) {
    selectRoom().updateWith(roomInfo);
}
export function fetchAndUpdateRoomInfo(roomId) {
    return bindDependencies(async function () {
        const roomRes = await fetchRoomInfo(roomId ?? selectRoom().slug.peek());
        updateRoomInfo(roomRes);
        return roomRes;
    }, []);
}
export async function updateServerPublicId(roomRes) {
    return bindDependencies(async function (roomsAPIService, peerCommunicator) {
        if (roomRes.owner === AuthService.getCorepassId()?.split(":")[1]) {
            // if room has an active publicId then we'll just join the room as a normal peer
            const CRIRes = await fetchCompactRoomInfo(roomRes.publicId);
            if (!CRIRes) {
                await roomsAPIService.updateRoom(roomRes.title, { publicId: peerCommunicator.peerId });
                return true;
            }
        }
        return false;
    }, [TYPES.RoomsApiService, TYPES.PeerCommunicator]);
}
export function createRoomToJoin(roomId) {
    return bindDependencies(async function (roomService) {
        const roomRes = await fetchRoomInfo(roomId);
        let roomStatus = RoomStatus.joining;
        if (await updateServerPublicId(roomRes)) {
            roomStatus = RoomStatus.starting;
        }
        //TODO: add rest of the items from rooms API
        roomService.createRoom({
            status: roomStatus,
            title: roomRes.title,
            type: roomRes.type,
            publicId: roomRes.publicId,
            slug: roomId,
        });
    }, [TYPES.RoomService]);
}
export function joinRoom(name) {
    return bindDependencies(async function (roomService) {
        selectRoomUISettings().isStreamControllersDisabled.set(true);
        await fetchAndUpdateRoomInfo();
        roomService.createAndSetSelfPeer(name, false);
        const corepassId = AuthService.getCorepassId();
        const avatarURL = AuthService.getAvatarUrl();
        const peerStreamSettings = selectStreamSettings().peek();
        await roomService.joinRoom(name, peerStreamSettings, corepassId, avatarURL);
        selectRoomUISettings().isStreamControllersDisabled.set(false);
    }, [TYPES.RoomService]);
}
export function leaveRoom() {
    return bindDependencies(async (rTCConnectionService) => {
        history.push(routePaths.HOME());
        const selfPeer = selectRoomSelfPeer().peek();
        selectRoom().changeRoomStatus(RoomStatus.leaving);
        //firstly, send a message to other peers informing of your leave
        const leaveRoomMessage = DCMessageBuilder.BuildLeaveRoomMessage(selfPeer);
        rTCConnectionService.sendMessage(leaveRoomMessage);
        return await resetToInitialState();
    }, [TYPES.RTCConnectionService]);
}
export function resetToInitialState() {
    return bindDependencies(async (selfStreamService, peerCommunicator, rtcConnectionService, speakerService) => {
        // close rtc connections
        rtcConnectionService.close();
        // reset self stream related resources
        await selfStreamService.clear();
        // reset speaker center
        speakerService.close();
        speakerService.init();
        // reset p2p connection
        await peerCommunicator.close();
        await peerCommunicator.init();
        // TODO: live chat, live stream related resources
        selectMeeting().room.set(null);
        selectMeetingUser().selfStream.delete();
        selectMeetingUser().analyzerFilter.delete();
    }, [TYPES.SelfStreamService, TYPES.PeerCommunicator, TYPES.RTCConnectionService, TYPES.SpeakerService]);
}
export function endCall() {
    return bindDependencies(async (rtcConnectionService, roomsAPIService) => {
        history.push(routePaths.HOME());
        const room = selectRoom().peek();
        roomsAPIService.endRoom(room.slug);
        const selfPeer = selectRoomSelfPeer().peek();
        //firstly, send a message to other peers informing of your leave
        selectRoom().changeRoomStatus(RoomStatus.ended);
        const endCallMessage = DCMessageBuilder.BuildEndCallMessage(selfPeer);
        rtcConnectionService.sendMessage(endCallMessage);
        return await resetToInitialState();
    }, [TYPES.RTCConnectionService, TYPES.RoomsApiService]);
}
export function toggleRaisingHand() {
    return bindDependencies(async function (raiseHandService) {
        raiseHandService.toggleRaisingHand();
    }, [TYPES.RaiseHandService]);
}

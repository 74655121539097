var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import EventEmitter from "events";
import logger from "loglevel";
import { injectable } from "inversify";
import { Multiaddr } from "multiaddr";
import { pipe } from "it-pipe";
import { libp2pIceServers } from "./constants";
import { ConnectionStatus, OtherEvents, PeerCommunicatorEvents, Protocols, } from "./types";
import { buffToArray, getRequestFrom, getResponseFrom } from "./utils";
import { ApiResponse, Code, Status } from "./messages";
import { Any } from "./messages/google/protobuf/any";
let Libp2pCommunicator = class Libp2pCommunicator extends EventEmitter {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "nodeStartRetries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "node", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "listenersMap", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        Object.defineProperty(this, "getAddress", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (peerId) => {
                return new Multiaddr(`/dns4/webrtc.ting.tube/tcp/443/wss/p2p-webrtc-star/p2p/${peerId}`);
            }
        });
        Object.defineProperty(this, "dial", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (addr, protocol) => {
                return this.node.dialProtocol(addr, protocol, {
                    spOptions: {
                        config: {
                            iceServers: libp2pIceServers,
                        },
                    },
                });
            }
        });
    }
    async startNode() {
        try {
            await this.node.start();
            this.emit(PeerCommunicatorEvents.connectionStatus, ConnectionStatus.CONNECTED);
            logger.debug("Node started");
        }
        catch (e) {
            logger.log(e);
            if (this.nodeStartRetries < 5) {
                this.startNode();
                this.nodeStartRetries += 1;
                logger.info("Node start failed, retrying", this.nodeStartRetries);
            }
            else {
                logger.error("failed to start");
                this.emit(PeerCommunicatorEvents.connectionStatus, ConnectionStatus.FAILED);
            }
        }
    }
    createNode(peerId) {
        this.emit(PeerCommunicatorEvents.connectionStatus, ConnectionStatus.CONNECTING);
        return import("./createNode")
            .then(module => module.createNode(peerId))
            .then(node => {
            this.node = node;
            this.startNode();
        });
    }
    async init() {
        await this.createNode();
        Object.keys(Protocols).forEach(protocol => {
            this.addEventHandler(Protocols[protocol]);
        });
    }
    get peerId() {
        return this.node.peerId.toString();
    }
    isInitiated() {
        if (!this.node)
            return false;
        return this.node.isStarted();
    }
    close() {
        return this.node?.stop();
    }
    async restart() {
        try {
            logger.debug("restarting the libp2p connection");
            await this.close();
            await this.createNode();
        }
        catch (error) {
            logger.error(error);
        }
    }
    async send(peerId, data, protocol) {
        const { stream } = await this.dial(this.getAddress(peerId), protocol);
        const requestClass = getRequestFrom(protocol);
        pipe([getRequestFrom(protocol).toBinary(requestClass.create(data))], stream);
        return pipe(stream.source, async (source) => {
            let response;
            for await (const msg of source) {
                response = ApiResponse.fromBinary(buffToArray(msg));
                logger.debug("get message response", response);
            }
            if (response.status.code !== Code.OK)
                throw response.status;
            return Any.unpack(response.payload, getResponseFrom(protocol));
        });
    }
    addEventHandler(eventName) {
        this.node.handle(eventName, data => {
            this.emit(eventName, data);
        });
    }
    eventParser(protocol, callback) {
        return function (props) {
            const { stream, connection } = props;
            const peerId = connection.remotePeer.toString();
            let data;
            return pipe(stream, async (source) => {
                for await (const msg of source) {
                    data = getRequestFrom(protocol).fromBinary(buffToArray(msg));
                    logger.debug(`got event ${protocol}: `, data);
                }
                let response, status;
                try {
                    response = await callback(peerId, data);
                    status = { code: Code.OK };
                }
                catch (e) {
                    if (Status.is(e)) {
                        status = e;
                    }
                    else {
                        status = { code: Code.UNKNOWN };
                    }
                }
                const responseClass = getResponseFrom(protocol);
                const apiResponse = ApiResponse.create({
                    status,
                    payload: response ? Any.pack(responseClass.create(response), responseClass) : null,
                });
                await pipe([ApiResponse.toBinary(apiResponse)], stream);
            });
        };
    }
    on(eventName, listener) {
        let handler;
        // TODO: Improve typing of eventName and how to seperate OtherEvents from protocols
        if (eventName === OtherEvents.connectionStatus) {
            handler = listener;
        }
        else {
            handler = this.eventParser(eventName, listener);
        }
        this.listenersMap.set(listener, handler);
        super.on(eventName, handler);
        return this;
    }
    off(eventName, listener) {
        const handler = this.listenersMap.get(listener);
        super.off(eventName, handler);
        return this;
    }
    async ping(peerId) {
        return this.node.ping(this.getAddress(peerId));
    }
};
Libp2pCommunicator = __decorate([
    injectable()
], Libp2pCommunicator);
export { Libp2pCommunicator };

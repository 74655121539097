import { CanvasTextMetrics, Graphics, Sprite, Text, TextStyle } from "pixi.js";
export const StageText = (source, renderer) => {
    const { style, source: baseSource } = source;
    const textStyle = new TextStyle(style);
    const metrics = CanvasTextMetrics.measureText(baseSource, textStyle);
    const width = metrics.width + (style.padding || 0);
    const height = metrics.height + (style.padding || 0);
    // Create source frame
    const frame = new Graphics({ label: "frame" })
        .roundRect(0, 0, width, height, 0)
        .stroke({ width: 6, color: "#ff9920", alpha: 1 });
    // Create background texture
    const graphic = new Graphics({ label: "background" })
        .roundRect(0, 0, width, height, 0)
        .fill(style.background || "transparent");
    const texture = renderer.generateTexture(graphic);
    // Create background sprite
    const sprite = new Sprite({
        texture,
        width,
        height,
        eventMode: "static",
    });
    // Create text sprite
    const text = new Text({
        text: baseSource,
        style: textStyle,
        x: style.padding ? style.padding / 2 : 0,
        y: style.padding ? style.padding / 2 : 0,
    });
    return { sprite, text, frame, width, height };
};

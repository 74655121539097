var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _StageSource_instances, _StageSource_prepareBaseSource, _StageSource_detectCornerHover, _StageSource_assignFrameInteractions, _StageSource_onDragStart, _StageSource_onDragEnd, _StageSource_onDragMove;
import { Container, Graphics, Sprite, Texture } from "pixi.js";
import { load as LoadWebFont } from "webfontloader";
import { StageRetrievalFrame, StageText, StageShape } from "./";
import { mapStudioSource, updateSourcePosition, updateSourceSize } from "@src/controller";
import { SourceKind } from "@src/domain/Studio/types";
export class StageSource {
    constructor(options) {
        _StageSource_instances.add(this);
        Object.defineProperty(this, "renderer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "container", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sprite", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "text", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "frame", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "source", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "label", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "resizing", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "dragStartPosition", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: undefined
        });
        Object.defineProperty(this, "width", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "height", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "x", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "y", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isDragging", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "lock", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "timeoutId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        const { source } = options;
        const { id, width, height, flipX, flipY, positionX: x, positionY: y, isRetrieval, sourceKind } = source;
        this.renderer = options.renderer;
        this.source = source;
        this.label = id;
        // Create source container
        this.container = new Container({
            label: id,
            width,
            height,
            alpha: 1,
            eventMode: "dynamic",
            isRenderGroup: true,
            pivot: { x: width / 2, y: height / 2 },
            position: { x: x + width / 2, y: y + height / 2 },
            scale: { x: flipX ? -1 : 1, y: flipY ? -1 : 1 },
        });
        if (isRetrieval) {
            this.container.addChild(StageRetrievalFrame({
                buttonText: `Map ${sourceKind}`, // todo use local lang for text
                width,
                height,
                onPress() {
                    mapStudioSource(source);
                },
            }));
            return;
        }
        __classPrivateFieldGet(this, _StageSource_instances, "m", _StageSource_prepareBaseSource).call(this, source).then(baseSource => {
            // Text source
            if (sourceKind === SourceKind.SHAPE) {
                this.addShapeSource(source);
            }
            else if (sourceKind === SourceKind.TEXT) {
                this.addTextSource(source);
            }
            else {
                // Create source frame
                this.frame = new Graphics({ label: "frame" })
                    .rect(x, y, width, height)
                    .stroke({ width: 6, color: "#ff9920", alpha: 1 });
                // Create source sprite
                this.sprite = new Sprite({
                    texture: Texture.from(baseSource),
                    width,
                    height,
                    eventMode: "static",
                });
                // add source sprite
                this.container.addChild(this.sprite, this.frame);
            }
            __classPrivateFieldGet(this, _StageSource_instances, "m", _StageSource_assignFrameInteractions).call(this, options);
        });
    }
    addTextSource(source) {
        const { style: { fontFamily }, } = source;
        const addText = () => {
            const { sprite, text, frame, width, height } = StageText(source, this.renderer);
            this.sprite = sprite;
            this.text = text;
            this.frame = frame;
            this.container.removeChildren(0);
            this.container.addChild(sprite, text, frame);
            this.container.width = width + 4;
            this.container.height = height + 4;
        };
        if (fontFamily && !document.querySelectorAll(`link[href*="${fontFamily.replace(" ", "+")}"]`).length) {
            LoadWebFont({
                google: { families: [fontFamily] },
                active: () => {
                    addText();
                },
            });
        }
        else
            addText();
    }
    addShapeSource(source) {
        const { sprite, frame, width, height } = StageShape(source, this.renderer);
        this.sprite = sprite;
        this.frame = frame;
        this.container.removeChildren(0);
        this.container.addChild(sprite, frame);
        this.container.width = width + 4;
        this.container.height = height + 4;
    }
    setLock(locked) {
        this.lock = locked;
    }
    getFrame(container) {
        const frame = container?.getChildByName("frame");
        if (!frame && container?.children?.length)
            return this.getFrame(container.children[0]);
        return frame;
    }
    drawFrame() {
        if (this.frame) {
            const color = this.lock ? "#ff0000" : "#ff9920";
            this.frame.clear().rect(0, 0, this.container.width, this.container.height).stroke({ width: 6, color, alpha: 1 });
        }
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
            this.removeFrame();
        }, 500);
    }
    removeFrame(container) {
        if (container)
            this.getFrame(container)?.clear();
        else
            this.frame?.clear();
    }
    clearFrames() {
        this.container.parent.children.map(child => {
            if (this.container.label !== child.label) {
                this.removeFrame(child);
            }
            else
                this.drawFrame();
        });
    }
}
_StageSource_instances = new WeakSet(), _StageSource_prepareBaseSource = async function _StageSource_prepareBaseSource(source) {
    if (!source.source)
        return null;
    // MediaStream source (Camera, Screen, Participants sources)
    if (source.source instanceof MediaStream) {
        const video = document.createElement("video");
        video.srcObject = source.source;
        video.muted = true;
        await video.play();
        return video;
    }
    // Local video file source
    else if (source.sourceKind === SourceKind.VIDEO_FILE) {
        const video = source.source;
        video.currentTime = 0;
        await video.play();
        setTimeout(() => {
            video.pause();
        }, 50);
        return video;
    }
    // Local image file source
    else if (source.sourceKind === SourceKind.IMAGE_FILE)
        return source.source;
    // Text source
    else if (source.sourceKind === SourceKind.TEXT)
        return source.source;
    else
        return null;
}, _StageSource_detectCornerHover = function _StageSource_detectCornerHover(event) {
    const { x, y } = event.global;
    const border = 15;
    const [cX, cY] = [this.container.x - this.container.width / 2, this.container.y - this.container.height / 2];
    const sW = cX + this.container.width;
    const sH = cY + this.container.height;
    return (x > sW - border && y > sH - border) || (x > sW + border && y > sH + border);
}, _StageSource_assignFrameInteractions = function _StageSource_assignFrameInteractions(options) {
    const { onPointerDown, onPointerUp, onClick } = options;
    // Add pointer listeners
    this.container
        .on("pointerdown", e => {
        __classPrivateFieldGet(this, _StageSource_instances, "m", _StageSource_onDragStart).call(this, e);
        if (onPointerDown)
            onPointerDown(this.label);
    })
        .on("pointerup", () => {
        __classPrivateFieldGet(this, _StageSource_instances, "m", _StageSource_onDragEnd).call(this);
        if (onPointerUp)
            onPointerUp(this.label);
    })
        .on("pointerout", () => {
        __classPrivateFieldGet(this, _StageSource_instances, "m", _StageSource_onDragEnd).call(this);
        this.removeFrame();
    })
        .on("pointermove", e => {
        this.drawFrame();
        __classPrivateFieldGet(this, _StageSource_instances, "m", _StageSource_onDragMove).call(this, e);
    })
        // .on("pointerover", () => {})
        .on("click", () => {
        if (onClick)
            onClick(this.label);
    });
}, _StageSource_onDragStart = function _StageSource_onDragStart(event) {
    if (this.lock)
        return;
    const { x, y } = event.global;
    if (!this.isDragging) {
        this.dragStartPosition = {
            x: x - this.container.x,
            y: y - this.container.y,
        };
    }
    const corner = __classPrivateFieldGet(this, _StageSource_instances, "m", _StageSource_detectCornerHover).call(this, event);
    this.isDragging = !corner;
    this.resizing = corner;
    if (!corner) {
        this.container.alpha = 0.8;
    }
    // Update frame index
    this.clearFrames();
    this.container.setChildIndex(this.sprite, 0);
    this.container.setChildIndex(this.frame, 1);
}, _StageSource_onDragEnd = function _StageSource_onDragEnd() {
    this.isDragging = false;
    if (this.dragStartPosition)
        this.dragStartPosition = undefined;
    const { label, width, height, x, y } = this.container;
    this.container.alpha = 1;
    this.container.pivot.set(width / 2, height / 2);
    updateSourceSize(label, width, height, false);
    updateSourcePosition(label, x - width / 2, y - height / 2, false);
}, _StageSource_onDragMove = function _StageSource_onDragMove(event) {
    // Handle pointer icon
    const corner = __classPrivateFieldGet(this, _StageSource_instances, "m", _StageSource_detectCornerHover).call(this, event);
    if (this.lock) {
        if (this.sprite)
            this.sprite.cursor = "default";
        return;
    }
    else if (this.sprite) {
        this.sprite.cursor = corner ? "nwse-resize" : "move";
    }
    // Update position and size
    if (this.isDragging && this.dragStartPosition) {
        const { x, y } = event.global;
        this.container.position.set(x - this.dragStartPosition.x, y - this.dragStartPosition.y);
    }
    else if (this.resizing && this.dragStartPosition) {
        const { x } = event.global;
        const cX = this.container.x - this.container.width / 2;
        const width = x - cX + 65;
        const height = width / this.source.ratio;
        this.sprite.setSize(width, height);
        this.container.setSize(width, height);
        this.container.pivot.set(width / 2, height / 2);
    }
};

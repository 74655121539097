/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import utLiveChat from "@ting/live-chat-extension";
import logger from "loglevel";
import { nanoid } from "nanoid";
import configs from "@src/configs";
import { Encryption } from "@src/utils/encryption";
export class LiveChatWebTorrentUtil {
    constructor() {
        Object.defineProperty(this, "torrentOrMagnet", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "newMessageHandler", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "webtorrent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "torrent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "unsentLiveMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sentPendingMessagesTries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
    }
    static get Instance() {
        if (!LiveChatWebTorrentUtil._instance) {
            LiveChatWebTorrentUtil._instance = new LiveChatWebTorrentUtil();
        }
        return LiveChatWebTorrentUtil._instance;
    }
    async initialize(options) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const WebTorrent = await import("webtorrent/dist/webtorrent.min");
        this.webtorrent = new WebTorrent({
            tracker: {
                rtcConfig: {
                    iceServers: configs.webrtc.iceServers,
                },
            },
            dht: false,
        });
        this.torrentOrMagnet = options.torrentOrMagnet;
        this.newMessageHandler = options.onNewMessageReceived;
        this.unsentLiveMessages = [];
        this.addTorrent(this.torrentOrMagnet);
    }
    dispose() {
        try {
            this.stopTorrent(this.torrent);
            this.webtorrent.remove(this.torrent);
            this.newMessageHandler = null;
        }
        catch {
            // we don't care, maybe it didn't have time to add the torrent at all
        }
    }
    getTorrent() {
        return this.torrent;
    }
    getWebtorrent() {
        return this.webtorrent;
    }
    // Sends a new live message to connected peers
    sendNewLiveChatMessage(message) {
        const torrent = this.torrent;
        if (torrent?.wires) {
            const supportingLiveChat = torrent.wires.filter((wire) => wire.peerExtendedMapping.ut_live_chat);
            console.log(`We have ${torrent.wires.length} connected wires, ${supportingLiveChat.length} of them support live chat`);
            console.log(`Sending message with id ${message.id} to ${supportingLiveChat.length} peers`);
            if (supportingLiveChat.length > 0) {
                supportingLiveChat.forEach((wire) => wire.extended("ut_live_chat", { ...message }));
            }
            else {
                console.log("No one to send it to, saving to pending messages for now...");
                this.unsentLiveMessages.push({ ...message });
            }
        }
    }
    sendPendingLiveMessages() {
        const torrent = this.torrent;
        if (this.unsentLiveMessages.length > 0) {
            const supportingLiveChat = torrent.wires.filter((wire) => wire.peerExtendedMapping.ut_live_chat);
            this.unsentLiveMessages.forEach(message => {
                supportingLiveChat.forEach((wire) => wire.extended("ut_live_chat", { ...message }));
            });
            // sending to at least 7 peers before flushing the pending messages
            if (this.sentPendingMessagesTries >= 7) {
                this.unsentLiveMessages = [];
            }
        }
    }
    seedFile(fileInfo) {
        return new Promise(resolve => {
            Encryption.encryptData(fileInfo).then(({ key, data, iv }) => {
                this.webtorrent.seed(data, (torrent) => {
                    const fileId = nanoid();
                    resolve({
                        fileName: fileInfo.name,
                        fileSize: fileInfo.size.toString(),
                        magnetUri: torrent.magnetURI,
                        decryptKey: key,
                        id: fileId,
                        fileType: fileInfo.type,
                        iv,
                    });
                });
            });
        });
    }
    async downloadTorrentFile(fileData, onStatusChange) {
        onStatusChange("started");
        const torrentFile = await this.webtorrent.get(fileData.magnetUri);
        if (!torrentFile) {
            this.webtorrent.add(fileData.magnetUri, (torrent) => {
                this.handleTorrent(torrent, fileData, onStatusChange);
            });
        }
        else {
            onStatusChange("downloaded");
            this.downloadFile(torrentFile, fileData);
        }
    }
    handleTorrent(torrent, fileData, onStatusChange) {
        torrent.on("done", () => {
            onStatusChange("downloaded");
            this.downloadFile(torrent, fileData);
        });
        torrent.on("error", err => {
            onStatusChange("failed");
            logger.error("torrent error", err);
        });
    }
    downloadFile(torrent, fileData) {
        if (!torrent.files || torrent.files.length === 0) {
            logger.error("No files found in torrent");
            return;
        }
        const file = torrent.files[0];
        const fileStream = file.createReadStream();
        const chunks = [];
        fileStream.on("data", (chunk) => {
            chunks.push(chunk);
        });
        fileStream.on("end", () => {
            const fileBuffer = Buffer.concat(chunks);
            const blob = new Blob([fileBuffer], { type: fileData.fileType });
            Encryption.decryptData(blob, fileData.decryptKey, fileData.fileType, fileData.iv)
                .then(decryptedFile => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(decryptedFile);
                link.setAttribute("download", fileData.fileName || "download");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
                .catch(error => {
                logger.error("Error decrypting file:", error);
            });
        });
        fileStream.on("error", error => {
            logger.error("Error reading file stream:", error);
        });
    }
    addTorrent(magnetOrTorrentUrl) {
        const torrentOptions = {};
        this.torrent = this.webtorrent.add(magnetOrTorrentUrl, torrentOptions, (torrent) => {
            this.extendLiveChat(torrent);
        });
        this.torrent.on("warning", (err) => {
            // We don't support HTTP tracker but we don't care -> we use the web socket tracker
            if (err.message.indexOf("Unsupported tracker protocol") !== -1)
                return null;
            // Users don't care about issues with WebRTC, but developers do so log it in the console
            if (err.message.indexOf("Ice connection failed") !== -1) {
                console.log(err);
                return null;
            }
            // Magnet hash is not up to date with the torrent file, add directly the torrent file
            if (err.message.indexOf("incorrect info hash") !== -1) {
                console.error("Incorrect info hash detected.");
            }
            // Remote instance is down
            if (err.message.indexOf("from xs param") !== -1) {
                console.error("Remote instance is down");
            }
            return null;
        });
    }
    stopTorrent(torrent) {
        torrent.pause();
        // Pause does not remove actual peers (in particular the webseed peer)
        torrent.removePeer(torrent.ws);
    }
    extendLiveChat(torrent) {
        torrent.on("wire", (wire) => {
            console.log(`Hooray, we've got a new peer (we are ${this.torrent.wires.length} now), wire: `, wire);
            wire.use(utLiveChat(torrent));
            wire.ut_live_chat.on("chat", (msg) => {
                console.log(`Received a new message from ${wire.peerId}`, msg);
                this.newMessageHandler(msg);
            });
            // wire.ut_live_chat.on('handshakeComplete', () => this.sendPendingLiveMessages())
            setTimeout(() => this.sendPendingLiveMessages(), 2000);
        });
    }
}

import { default as app } from "./app";
import { default as auth } from "./auth";
import { default as images } from "./images";
import { default as mediaStream } from "./mediaStream";
import { default as studio } from "./studio";
import { defaultSelfStreamSettings } from "./StreamSettings";
import { initialRoomUISettings } from "./initialRoomUISettings";
import { default as webrtc } from "./webrtc";
import { default as google } from "./google";
import { default as missingTrack } from "./missingTrack";
import { default as galleryView } from "./galleryView";
export default {
    app,
    auth,
    images,
    mediaStream,
    studio,
    defaultSelfStreamSettings,
    webrtc,
    initialRoomUISettings,
    google,
    missingTrack,
    galleryView,
};

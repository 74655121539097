export var SourceKind;
(function (SourceKind) {
    // Mic & Cam
    SourceKind["AUDIO_OUTPUT"] = "audiooutput";
    SourceKind["AUDIO_INPUT"] = "audioinput";
    SourceKind["VIDEO_OUTPUT"] = "videooutput";
    SourceKind["VIDEO_INPUT"] = "videoinput";
    // Screens (MediaStream sources)
    SourceKind["DISPLAY_CAPTURE"] = "displaycapture";
    SourceKind["WINDOW_CAPTURE"] = "windowcapture";
    SourceKind["BROWSER_CAPTURE"] = "browsercapture";
    // Files
    SourceKind["VIDEO_FILE"] = "videofile";
    SourceKind["AUDIO_FILE"] = "audiofile";
    SourceKind["IMAGE_FILE"] = "imagefile";
    // Primary
    SourceKind["TEXT"] = "text";
    SourceKind["SHAPE"] = "shape";
})(SourceKind || (SourceKind = {}));

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { beginBatch, endBatch } from "@legendapp/state";
import { injectable, preDestroy } from "inversify";
import { RoomStatus } from "@src/domain/Room";
import { selectRoom, selectRoomPeers } from "@src/selectors";
import configs from "@src/configs";
let SpeakerService = class SpeakerService {
    constructor() {
        Object.defineProperty(this, "interval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "context", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "fftSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 256
        });
        Object.defineProperty(this, "intervalDuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 50
        });
        this.init();
    }
    init() {
        this.context = new (window.AudioContext || window.webkitAudioContext)();
    }
    startTracking() {
        this.context.resume();
        this.analyzePeerAudios = this.analyzePeerAudios.bind(this);
        // TODO: use animationframe?
        this.interval = setInterval(this.analyzePeerAudios, this.intervalDuration);
    }
    stopTracking() {
        clearInterval(this.interval);
        this.context.suspend();
    }
    close() {
        clearInterval(this.interval);
        this.context.close();
    }
    createTalkingInfoForPeer(peer) {
        const [sourceNode, analyzer] = this.makeSourceNodeForPeer(peer);
        // analyzer.smoothingTimeConstant = 0.8;
        return {
            isSpeaking: false,
            sourceNode,
            analyzer,
        };
    }
    isSourceNodeValid(peer) {
        const sourceNode = peer.talkingInfo.sourceNode;
        return sourceNode && sourceNode.mediaStream.getTracks()[0] === peer?.peerStream?.getAudioTracks()[0];
    }
    makeSourceNodeForPeer(peer) {
        if (peer.peerStream?.getAudioTracks()?.[0]?.readyState === "live") {
            const stream = new MediaStream();
            stream.addTrack(peer.peerStream.getAudioTracks()[0]);
            const source = this.context.createMediaStreamSource(stream);
            const analyzer = this.context.createAnalyser();
            analyzer.fftSize = this.fftSize;
            source.connect(analyzer);
            return [source, analyzer];
        }
        else {
            return [null, null];
        }
    }
    getOrCreateTalkingInfoForPeer(peer) {
        if (peer.talkingInfo && this.isSourceNodeValid(peer)) {
            return Object.assign({}, peer.talkingInfo);
        }
        else if (peer.talkingInfo && !this.isSourceNodeValid(peer)) {
            const [sourceNode, analyzer] = this.makeSourceNodeForPeer(peer);
            return Object.assign({}, peer.talkingInfo, { sourceNode, analyzer });
        }
        else if (!peer.talkingInfo) {
            return this.createTalkingInfoForPeer(peer);
        }
    }
    calculateAudioIntensity(data) {
        const range = 64;
        const sensitivity = 0.5;
        const to = Math.floor(range + 20);
        const from = Math.ceil(to - range);
        const segment = data.slice(from, to);
        const sum = segment.reduce((a, b) => a + b);
        const average = sum / segment.length;
        const height = sensitivity * average;
        return height;
    }
    // TODO: maybe we should go a little easier on the guys who was speaking, and lower the intensity for them
    // or maybe consider amount of time spent speaking in a time window instead of the lastStartedSpeaking
    updateTalkingInfo(talkingInfo, intensity) {
        const updatedTalkingInfo = {
            isSpeaking: intensity > configs.galleryView.minSpeakingIntensity,
            sourceNode: talkingInfo.sourceNode,
            analyzer: talkingInfo.analyzer,
            soundIntensity: intensity,
            lastEndedSpeaking: talkingInfo.lastEndedSpeaking,
            lastStartedSpeaking: talkingInfo.lastStartedSpeaking,
        };
        if (updatedTalkingInfo.isSpeaking && !talkingInfo.isSpeaking) {
            updatedTalkingInfo.lastStartedSpeaking = new Date();
        }
        if (!updatedTalkingInfo.isSpeaking && talkingInfo.isSpeaking) {
            updatedTalkingInfo.lastEndedSpeaking = new Date();
        }
        return updatedTalkingInfo;
    }
    analyzePeerAudios() {
        const roomStatus = selectRoom().status.peek();
        if (roomStatus !== RoomStatus.active)
            return;
        const peers$ = selectRoomPeers();
        beginBatch();
        for (const [, peer$] of Object.entries(peers$)) {
            const audioData = new Uint8Array(this.fftSize / 2);
            const talkingInfo = this.getOrCreateTalkingInfoForPeer(peer$.peek());
            if (!talkingInfo.sourceNode)
                continue;
            // if mic is off don't bother analyzing data
            if (!peer$.peerStreamSettings.isMicOn.peek()) {
                peer$.talkingInfo.set(this.updateTalkingInfo(talkingInfo, 0));
                continue;
            }
            talkingInfo.analyzer.getByteFrequencyData(audioData);
            const intensity = this.calculateAudioIntensity(audioData);
            const updatedTalkingInfo = this.updateTalkingInfo(talkingInfo, intensity);
            peer$.talkingInfo.set(updatedTalkingInfo);
        }
        endBatch();
    }
};
__decorate([
    preDestroy(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], SpeakerService.prototype, "close", null);
SpeakerService = __decorate([
    injectable(),
    __metadata("design:paramtypes", [])
], SpeakerService);
export { SpeakerService };

import { observable } from "@legendapp/state";
import { configureObservablePersistence, persistObservable } from "@legendapp/state/persist";
import { ObservablePersistLocalStorage } from "@legendapp/state/persist-plugins/local-storage";
import configs from "./configs";
import { Studio } from "@src/domain/Studio";
import { Meeting } from "@src/domain/Meeting";
configureObservablePersistence({
    // Use Local Storage
    persistLocal: ObservablePersistLocalStorage,
});
export const state = observable({
    meeting: Meeting.instance,
    meetingUser: undefined,
    auth: { inProgress: false, user: undefined, shouldRegister: false },
    roomUISettings: Object.assign({}, configs.initialRoomUISettings),
});
persistObservable(state.meeting.studio, {
    local: {
        name: configs.app.studioRestoreLocalName,
        adjustData: {
            load: data => new Studio(data),
            // load: () => new Studio(),
        },
    },
});
window.state = state;

import { Application, Container, Rectangle } from "pixi.js";
import logger from "loglevel";
import { StageSource } from "./components";
import { ENV } from "@src/env";
import configs from "@src/configs";
import { selectStudioActiveScene, selectStudioSavedState } from "@src/selectors/studio";
import { activateSource, restoreStudioData } from "@src/controller";
class Stage {
    constructor() {
        Object.defineProperty(this, "initiated", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "app", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "width", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "height", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ratio", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "scenes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "sources", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "tickers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        this.app = new Application();
    }
    init(parentElement) {
        if (!this.initiated) {
            this.initiated = true;
            const studioState = selectStudioSavedState().peek();
            this.app
                .init({
                background: "#000",
                width: configs.studio.defaultOutput.width,
                height: configs.studio.defaultOutput.height,
                powerPreference: "high-performance",
                eventMode: "dynamic",
                sharedTicker: true,
                antialias: true,
                autoDensity: true,
                clearBeforeRender: true,
                resolution: 1,
                preference: "webgl",
            })
                .then(() => {
                // Append canvas into UI
                parentElement.append(this.app.canvas);
                // Set the ratio of canvas, used for live-stream
                this.setRatio();
                // Restore last session saved state of studio
                if (studioState)
                    restoreStudioData();
                // Add tickers
                this.tickers.forEach(cb => {
                    this.app.ticker.add(cb);
                });
            });
        }
        else if (this.app?.canvas)
            parentElement.append(this.app.canvas);
    }
    setApp(app) {
        this.app = app;
    }
    setRatio() {
        this.width = this.app.canvas.width;
        this.height = this.app.canvas.height;
        this.ratio = this.width / this.height;
    }
    switchScene(id) {
        this.app.stage.children.map(child => {
            this.app.stage.removeChild(child);
        });
        this.app.stage.addChild(this.scenes[id]);
    }
    // Add new Scene container to Stage
    addScene(scene) {
        if (this.app.stage.children.find((child) => child.label === scene.id)) {
            logger.warn("this scene has been added before!");
            return;
        }
        const container = new Container({
            label: scene.id,
            x: 0,
            y: 0,
            width: scene.width, // this.app.screen.width,
            height: scene.height, // this.app.screen.height,
            alpha: 1,
            eventMode: "dynamic",
            isRenderGroup: true, // this will make moving this container GPU powered
            boundsArea: new Rectangle(0, 0, scene.width, scene.height),
        });
        this.scenes[scene.id] = container;
        this.app.stage.addChild(container);
    }
    addSource(source) {
        this.sources[source.id] = new StageSource({
            renderer: this.app.renderer,
            source,
            onClick: (id) => activateSource(id),
        });
        this.highlightSourceFrame(source.id);
    }
    addList(sources) {
        try {
            const activeScene = selectStudioActiveScene().peek();
            const container = this.app.stage.getChildByLabel(activeScene.id);
            if (container) {
                container.removeChildren();
                sources.forEach((source, index) => {
                    container.addChildAt(this.sources[source.id].container, index);
                });
            }
        }
        catch (error) {
            logger.log("Stage addSourceList:", error);
        }
    }
    removeContainer(id) {
        const container = this.app.stage.children.find(child => child.label === id);
        // Remove child container
        if (container) {
            container.destroy(true);
        }
        else
            logger.warn(`RemoveContainer Failed: ${id} not found!`);
    }
    removeSource(id) {
        const activeScene = selectStudioActiveScene().peek();
        const container = this.app.stage.getChildByLabel(activeScene.id).children.find(child => child.label === id);
        // Remove child container
        if (container) {
            this.app.stage.getChildByLabel(activeScene.id).removeChild(container);
        }
        else
            logger.warn(`RemoveEntity Failed: ${id} not found!`);
        // Remove it from entity list
        delete this.sources[id];
    }
    highlightSourceFrame(id) {
        if (Object.keys(this.sources).length) {
            setTimeout(() => {
                Object.keys(this.sources).map(key => {
                    if (key === id)
                        this.sources[key].drawFrame();
                    else
                        this.sources[key].removeFrame();
                });
            }, 50);
        }
    }
    removeSourcesFrame() {
        if (Object.keys(this.sources).length) {
            Object.keys(this.sources).map(key => {
                this.sources[key].removeFrame();
            });
        }
    }
    updateSize(id, width, height) {
        const { container, sprite } = this.sources[id];
        sprite.setSize(width, height);
        container.setSize(width, height);
        container.pivot.set(width / 2, height / 2);
        setTimeout(() => {
            this.sources[id].drawFrame();
        }, 100);
    }
    updatePosition(id, x, y) {
        const { container } = this.sources[id];
        const [cHWidth, cHHeight] = [container.width / 2, container.height / 2];
        container.position.set(x + cHWidth, y + cHHeight);
        setTimeout(() => {
            this.sources[id].drawFrame();
        }, 100);
    }
    updateTextStyle(source) {
        this.sources[source.id].addTextSource(source);
    }
    updateShape(source) {
        this.sources[source.id].addShapeSource(source);
    }
    rotate(id, degree) {
        this.sources[id].container.angle = degree;
    }
    flip(id, dir, flipped) {
        this.sources[id].container.scale[dir] = flipped ? -1 : 1;
    }
    hide(id, hidden) {
        const activeScene = selectStudioActiveScene().peek();
        if (hidden) {
            this.app.stage.getChildByLabel(activeScene.id).removeChild(this.sources[id].container);
        }
        else {
            this.app.stage
                .getChildByLabel(activeScene.id)
                .addChildAt(this.sources[id].container, this.sources[id].source.index);
        }
    }
    lock(id, lock) {
        this.sources[id].setLock(!lock);
    }
    updateSourceIndex(id, index) {
        try {
            const activeScene = selectStudioActiveScene().peek();
            // Update source container index
            const container = this.app.stage.getChildByLabel(activeScene.id).getChildByLabel(id);
            if (container) {
                this.app.stage.getChildByLabel(activeScene.id).removeChild(container);
                this.app.stage.getChildByLabel(activeScene.id).addChildAt(container, index);
            }
        }
        catch (error) {
            logger.log("Stage updateSourceIndex:", error);
        }
    }
    sortIndexes() {
        try {
            const activeScene = selectStudioActiveScene().peek();
            this.app.stage.getChildByLabel(activeScene.id).children.map(child => {
                this.updateSourceIndex(child.label, this.sources[child.label].source.index);
            });
        }
        catch (error) {
            logger.error("Stage sortIndexes:", error);
        }
    }
    updateTransparency(id, transparency) {
        this.sources[id].container.alpha = transparency * 0.01;
    }
    async takeScreenshot() {
        const container = this.app.stage;
        this.app.stop();
        const url = await this.app.renderer?.extract.base64(container);
        this.app.start();
        return url;
    }
    addTicker(name, cb) {
        if (!this.tickers.has(name)) {
            this.tickers.set(name, cb);
            this.app?.ticker?.add(cb);
        }
    }
    removeTicker(name) {
        if (this.tickers.has(name)) {
            this.app.ticker.remove(this.tickers.get(name));
            this.tickers.delete(name);
        }
    }
}
export const StudioStage = new Stage();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (ENV.IS_DEV)
    window.StudioStage = StudioStage;

import { Container, Graphics, Text } from "pixi.js";
import { StageButton } from "./stageButton";
const size = (size, max) => {
    if (max < size)
        return max - 50;
    if (size + 100 > max)
        return size - 50;
    return size;
};
export const StageRetrievalFrame = ({ text, buttonText, width, height, onPress }) => {
    const [bWidth, bHeight] = [size(500, width), size(100, height)];
    const container = new Container({ label: "retrieval-frame", width, height, position: { x: 0, y: 0 } });
    const graphic = new Graphics({ label: "background" }).roundRect(0, 0, width, height, 0).fill("#212B3A");
    const frame = new Graphics({ label: "frame" })
        .roundRect(0, 0, width, height, 0)
        .stroke({ width: 4, color: "#3d4757", alpha: 1 });
    const button = StageButton({
        x: width / 2 - bWidth / 2,
        y: height / 2 - bHeight / 2,
        width: bWidth,
        height: bHeight,
        color: "#ffffff",
        background: "#364154",
        hover: {
            background: "#293343",
        },
        text: buttonText,
        onPress,
    });
    container.addChild(graphic, frame, button);
    if (text) {
        const txt = new Text({
            text,
            style: {
                fontSize: 40,
                fill: "#7a8699",
                breakWords: true,
                wordWrap: true,
                wordWrapWidth: (width / 10) * 9,
            },
        });
        txt.anchor.set(0.5);
        txt.position.set(width / 2, height / 1.3);
        container.addChild(txt);
    }
    return container;
};

import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
import { Application } from "pixi.js";
import loglevel from "loglevel";
import { tickerWorker } from "@src/utils/workers";
export class VideoFilter {
    constructor() {
        Object.defineProperty(this, "config", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "app", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "selfieSegmentation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "shouldApplyEffect", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "capturedStream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
    }
    /**
     *
     * @param config VideoFilterConfig
     * @returns boolean (shouldApplyEffect)
     * - Sometimes you don't need to call applyEffect() after updating config.
     * - This method returns a shouldApplyEffect (boolean) value to help you know when to apply effect
     * - 'true' means you should call applyEffect()
     * - 'false' means there's no need (note that, you won't spoil anything if you do)
     */
    updateConfig(config) {
        this.sanityCheck(config);
        this.shouldApplyEffect = this.checkShouldApplyEffect(config);
        this.config = { ...this.config, width: 670, height: 500, ...config };
        return this.shouldApplyEffect;
    }
    /**
     * Call this method if you get 'true', after calling updateConfig.
     * However, the method is smart enough, so even if you call it on 'false', nothing breaks.
     * @returns MediaStream
     */
    async applyEffect() {
        this.sanityCheck();
        if (!this.shouldApplyEffect && this.capturedStream)
            return this.capturedStream;
        await this.reset();
        await this.setup();
        this.capturedStream = this.app.canvas.captureStream(60);
        return this.capturedStream;
    }
    async disableEffect() {
        await this.reset();
        this.config = undefined;
        this.selfieSegmentation = undefined;
        this.app = undefined;
        this.capturedStream = undefined;
    }
    async reset() {
        tickerWorker.postMessage("stop");
        this.shouldApplyEffect = false;
        await this.selfieSegmentation?.close();
    }
    sanityCheck(config) {
        if (!this.config && !config)
            throw Error("No config available. First update config");
        if (!this.config?.stream && !config?.stream)
            throw Error("No stream available in config object");
        if (!this.config?.type && !config?.type)
            throw Error("No filter type available in config object");
    }
    /**
     *
     * @param config VideoFilterConfig
     * @returns boolean
     *
     * Checks if we should apply effect based on new config and old config
     */
    checkShouldApplyEffect(config) {
        if (!this.config || this.config.stream.id !== config.stream.id)
            return true;
        if (!config.height || !config.width)
            return false;
        if (this.config.height !== config.height || this.config.width !== config.width)
            return true;
        return false;
    }
    async setup() {
        try {
            tickerWorker.postMessage("start");
            const { width, height } = this.config;
            const { video, canvas, ctx } = getElements(this.config.stream);
            this.app = new Application();
            await initPixiAppWithSupportedRenderer(this.app, { width, height, canvas, powerPreference: "high-performance" });
            this.selfieSegmentation = await this.initSelfiSegmentation(canvas, ctx);
            tickerWorker.onmessage = event => {
                if (event.data === "tick" && this.selfieSegmentation)
                    this.selfieSegmentation.send({ image: video });
            };
        }
        catch (error) {
            await this.disableEffect();
            throw error;
        }
    }
    async initSelfiSegmentation(canvas, ctx) {
        const selfieSegmentation = new SelfieSegmentation({ locateFile: file => `/models/${file}` });
        selfieSegmentation.setOptions({ modelSelection: 1, selfieMode: false });
        selfieSegmentation.onResults(results => this.handleSegmentationResults(results, canvas, ctx));
        await selfieSegmentation.initialize();
        return selfieSegmentation;
    }
    handleSegmentationResults(results, canvas, ctx) {
        try {
            this.sanityCheck();
            ctx.save();
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            // draw segmentation mask
            ctx.filter = "none";
            ctx.globalCompositeOperation = "source-over";
            ctx.drawImage(results.segmentationMask, 0, 0, canvas.width, canvas.height);
            // draw imageFrame on top
            ctx.globalCompositeOperation = "source-in";
            ctx.drawImage(results.image, 0, 0, canvas.width, canvas.height);
            // apply effect
            ctx.globalCompositeOperation = "destination-over";
            if (this.config.type === "blur-bg") {
                const blur = convertBlurLevel2Pixel(this.config.level || "medium");
                ctx.filter = `blur(${blur})`;
                ctx.drawImage(results.image, 0, 0, canvas.width, canvas.height);
            }
            else {
                ctx.drawImage(this.config.image, 0, 0, canvas.width, canvas.height);
            }
            ctx.restore();
        }
        catch (error) {
            loglevel.error("VideoFilter Handler Error:", error);
        }
    }
}
const initPixiAppWithSupportedRenderer = async (app, options) => {
    try {
        await app.init({ ...options, preference: "webgl" });
    }
    catch (error) {
        await app.init({ ...options, preference: "webgpu" });
    }
};
const getElements = (stream) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx)
        throw Error("Could not create canvas context");
    const video = document.createElement("video");
    video.autoplay = true;
    video.srcObject = stream;
    return { video, canvas, ctx };
};
const convertBlurLevel2Pixel = (level) => {
    const map = {
        high: "30px",
        medium: "20px",
        low: "10px",
    };
    return map[level];
};

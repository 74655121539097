export class Peer {
    constructor({ name, peerId, avatarURL, corepassId, role, peerStreamSettings }) {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // number represents es epoch
        Object.defineProperty(this, "raisedHandAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "peerId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "avatarURL", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "corepassId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "peerStreamSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                isCamOn: false,
                isMicOn: false,
                isScreenOn: false,
            }
        });
        Object.defineProperty(this, "peerStream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "screenStream", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "permission", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "role", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "talkingInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = name;
        this.peerId = peerId;
        this.avatarURL = avatarURL;
        this.corepassId = corepassId;
        this.role = role;
        this.peerStreamSettings = peerStreamSettings ?? this.peerStreamSettings;
    }
    toggleMic(isMicOn) {
        this.peerStreamSettings.isMicOn.set(isMicOn !== undefined ? isMicOn : !this.peerStreamSettings.isMicOn.peek());
    }
    toggleCam(isCamOn) {
        this.peerStreamSettings.isCamOn.set(isCamOn !== undefined ? isCamOn : !this.peerStreamSettings.isCamOn.peek());
    }
    toggleScreen(isScreenOn) {
        this.peerStreamSettings.isScreenOn.set(isScreenOn !== undefined ? isScreenOn : !this.peerStreamSettings.isScreenOn.peek());
    }
    setRaisedHand(raisedAt) {
        this.raisedHandAt.set(raisedAt);
    }
    unsetRaisedHand() {
        this.raisedHandAt.set(null);
    }
    setPeerStream(stream) {
        this.peerStream.set(stream);
    }
    setScreenStream(stream) {
        this.screenStream.set(stream);
    }
    changeRole(role) {
        this.role.set(role);
    }
    changePermission(permission) {
        this.permission.set(permission);
    }
    toObject() {
        return {
            name: this.name,
            peerId: this.peerId,
            peerStreamSettings: {
                isCamOn: this.peerStreamSettings.isCamOn,
                isMicOn: this.peerStreamSettings.isMicOn,
                isScreenOn: this.peerStreamSettings.isScreenOn,
            },
            avatarURL: this.avatarURL,
            corepassId: this.corepassId,
            raisedHandAt: this.raisedHandAt,
            role: this.role,
            permission: this.permission,
        };
    }
    static fromObject(newPeer) {
        const p = new Peer({
            name: newPeer.name,
            peerId: newPeer.peerId,
            avatarURL: newPeer.avatarURL,
            corepassId: newPeer.corepassId,
            role: newPeer.role,
        });
        p.peerStreamSettings = newPeer.peerStreamSettings;
        p.permission = newPeer.permission;
        p.raisedHandAt = newPeer.raisedHandAt;
        return p;
    }
}

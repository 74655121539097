import { Container, Graphics, Text } from "pixi.js";
import { Button } from "@pixi/ui";
export const StageButton = ({ x, y, width, height, color, background, text, onPress, hover, }) => {
    const updateGraphic = (graphic, fill, alpha = 1) => {
        graphic.clear();
        graphic.roundRect(0, 0, width, height, 10).fill(fill);
        graphic.alpha = alpha;
    };
    const container = new Container({
        label: "button-container",
        width,
        height,
        position: { x, y },
    });
    const graphic = new Graphics();
    const graphicBtn = new Graphics();
    const button = new Button(graphicBtn);
    const txt = new Text({ text, style: { fontSize: (width / 100) * 6, fill: color } });
    txt.anchor.set(0.5);
    txt.position.set(width / 2, height / 2);
    // Update graphics
    updateGraphic(graphic, background);
    updateGraphic(graphicBtn, null, 0);
    // Add Events
    if (hover) {
        button.onHover.connect(() => {
            if (hover.color)
                txt.style.fill = hover.color;
            if (hover.background)
                updateGraphic(graphic, hover.background);
        });
        button.onOut.connect(() => {
            if (hover.color)
                txt.style.fill = color;
            if (hover.background)
                updateGraphic(graphic, background);
        });
    }
    if (onPress)
        button.onPress.connect(onPress);
    // Added assets to container
    container.addChild(graphic, txt, button.view);
    return container;
};

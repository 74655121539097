var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { injectable, inject } from "inversify";
import { playAudioNotification } from "../AudioNotifier";
import { TYPES } from "@src/ioc.type";
import { DCMessageType, PeerConnectionEvents } from "@src/domain/RTCConnection";
import { selectRoomPeer, selectRoomSelfPeer } from "@src/selectors";
import { DCMessageBuilder } from "@src/controller/helpers";
let RaisedHandService = class RaisedHandService {
    constructor(rtcConnectionService) {
        Object.defineProperty(this, "_rtcConnectionService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this._rtcConnectionService = rtcConnectionService;
    }
    init() {
        this._rtcConnectionService.on(PeerConnectionEvents.ReceivedDCMessage, (message) => {
            switch (message.type) {
                case DCMessageType.RISING_HAND: {
                    const { peerId, time } = message.payload;
                    selectRoomPeer(peerId).setRaisedHand(time);
                    playAudioNotification();
                    break;
                }
                case DCMessageType.STOP_RISING_HAND: {
                    const { peerId } = message.payload;
                    selectRoomPeer(peerId).unsetRaisedHand();
                    break;
                }
            }
        });
    }
    raiseHand() {
        const selfPeer = selectRoomSelfPeer().peek();
        const message = DCMessageBuilder.BuildRaisedHandMessage(selfPeer);
        this._rtcConnectionService.sendMessage(message);
        selectRoomSelfPeer().setRaisedHand(message.payload.time);
    }
    stopRaiseHand() {
        const selfPeer = selectRoomSelfPeer().peek();
        this._rtcConnectionService.sendMessage(DCMessageBuilder.BuildStopRaisedHandMessage(selfPeer));
        selectRoomSelfPeer().unsetRaisedHand();
    }
    toggleRaisingHand() {
        const raisingHand = selectRoomSelfPeer().raisedHandAt.peek();
        raisingHand ? this.stopRaiseHand() : this.raiseHand();
    }
};
RaisedHandService = __decorate([
    injectable(),
    __param(0, inject(TYPES.RTCConnectionService)),
    __metadata("design:paramtypes", [Object])
], RaisedHandService);
export { RaisedHandService };

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AttentionFilledIcon, StopVideoOutlinedIcon, VideoOutlinedIcon } from "@ting/ting-ui-components/dist/icons";
import { useState } from "react";
import { Button, Text } from "@ting/ting-ui-components";
import { useTranslation } from "react-i18next";
import { ControllerButtonSizes } from "../types";
import { classNameFactory } from "@src/utils/dom";
import { useSubscribe } from "@src/hooks/useSubscribe";
import { selectIsCamOn, selectRoomUISettings, selectVideoInputError } from "@src/selectors";
import { toggleCamOn } from "@src/controller/userMedia";
import { PermissionModal } from "@src/components/PermissionModal";
import { MediaInputErrors } from "@src/domain/SelfStream/types";
import "./camController.scss";
const cn = classNameFactory("cam-controller");
export const CamController = ({ withText = false, size = ControllerButtonSizes.medium }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation(["room"]);
    const videoInputError = useSubscribe(selectVideoInputError());
    const isStreamControllersDisabled = useSubscribe(selectRoomUISettings().isStreamControllersDisabled);
    const isCamOn = useSubscribe(selectIsCamOn());
    const changeCamOn = async () => {
        if (videoInputError == MediaInputErrors.NotAllowedError) {
            setOpen(true);
        }
        setIsLoading(true);
        await toggleCamOn(!isCamOn);
        setIsLoading(false);
    };
    const AttentionSize = size === ControllerButtonSizes.big ? 20 : 16;
    return (_jsxs("div", { className: cn(""), children: [_jsxs("div", { className: cn("buttons"), children: [videoInputError && (_jsx(AttentionFilledIcon, { width: AttentionSize, height: AttentionSize, className: cn("attention-icon") })), _jsx(Button, { v2: true, onClick: changeCamOn, size: size, disabled: isLoading || isStreamControllersDisabled, type: isCamOn ? "neutral-inverted" : "danger", startIcon: isCamOn ? _jsx(VideoOutlinedIcon, { width: 24, height: 24 }) : _jsx(StopVideoOutlinedIcon, { width: 24, height: 24 }), round: true })] }), withText && (_jsx(Text, { size: "body-medium", fontWeight: "regular", children: isCamOn ? t("video-on") : t("video-off") })), _jsx(PermissionModal, { camera: !!videoInputError, open: isOpen, onClose: () => setOpen(false) })] }));
};

export function soundLevel(lvl) {
    if (0 < lvl) {
        return "danger";
    }
    else if (-2 <= lvl && lvl < 0) {
        return "warn";
    }
    else if (lvl < -2) {
        return "ok";
    }
}

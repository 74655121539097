var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
import { inject, injectable } from "inversify";
import logger from "loglevel";
import { PeerRoles } from "@src/domain/Peer";
import { AuthService } from "@src/services/AuthService";
import { SelfPeer } from "@src/domain/Peer/SelfPeer";
import { IMediaTrackType, PeerConnectionEvents } from "@src/domain/RTCConnection";
import { selectRoom, selectRoomPeer, selectRoomPeers, selectRoomSelfPeer } from "@src/selectors";
import { TYPES } from "@src/ioc.type";
let PeerService = class PeerService {
    constructor(rtcConnectionService) {
        Object.defineProperty(this, "_rtcConnectionService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this._rtcConnectionService = rtcConnectionService;
        this.init();
    }
    init() {
        this._rtcConnectionService.on(PeerConnectionEvents.ReceivedTrack, ({ peerId, trackType, track, stream }) => {
            this.addTrackToPeer(peerId, trackType, track, stream);
        });
    }
    createSelfPeer(name, peerId, role = PeerRoles.SIMPLE) {
        const corepassId = AuthService.getCorepassId();
        const avatarURL = AuthService.getAvatarUrl();
        return new SelfPeer({
            name,
            peerId,
            role,
            corepassId,
            avatarURL,
        });
    }
    addTrackToPeer(peerId, trackType, track, stream) {
        const peer = selectRoomPeer(peerId);
        if (!peer.peek())
            throw new Error("Requested Peer does not exist");
        logger.info(`************Received Track From Peer ${peer.name.peek()}, track type: ${trackType}`);
        if ([
            IMediaTrackType.Audio,
            IMediaTrackType.Video,
            IMediaTrackType.ForwardedAudio,
            IMediaTrackType.ForwardedVideo,
        ].includes(trackType) &&
            stream.getTrackById(track.id)) {
            peer.setPeerStream(stream);
        }
        if ([
            IMediaTrackType.ScreenAudio,
            IMediaTrackType.ScreenVideo,
            IMediaTrackType.ForwardedScreenAudio,
            IMediaTrackType.ForwardedScreenVideo,
        ].includes(trackType) &&
            stream.getTrackById(track.id)) {
            peer.setScreenStream(stream);
        }
    }
    removePeer(peerId) {
        const $peer = selectRoomPeer(peerId);
        this._rtcConnectionService.removeConnectionFrom($peer.peek());
        $peer.delete();
        selectRoom().removePeer(peerId);
    }
    getSelfPeer() {
        return selectRoomSelfPeer().get();
    }
    getRoomPeers() {
        return selectRoomPeers().get();
    }
    addPeer(peer) {
        selectRoom().addPeer(peer);
    }
    getRoomPeersArray() {
        return Object.values(this.getRoomPeers());
    }
};
PeerService = __decorate([
    injectable(),
    __param(0, inject(TYPES.RTCConnectionService)),
    __metadata("design:paramtypes", [Object])
], PeerService);
export { PeerService };
